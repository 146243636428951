var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

import React from 'react';
import PropTypes from 'prop-types';
import APVerticalScroller from 'apex-web/lib/components/common/APVerticalScroller/APVerticalScroller';
import APInlineButton from 'apex-web/lib/components/common/APInlineButton';
import BulkCancelButtonsComponent from './BulkCancelButtonsComponent/BulkCancelButtonsComponent';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { isFirefox } from 'apex-web/lib/helpers/browserHelper';
import Level2DataTableComponent from './Level2DataTableComponent';

import '../../styles/components/TradeComponent.css';
import './OrderBookComponent.css';

var tradeComponentClasses = getBEMClasses('trade-component');
var orderBookClasses = getBEMClasses('orderbook');
var flexTable = getBEMClasses('flex-table');

var OrderBookComponent = (function(_React$Component) {
  _inherits(OrderBookComponent, _React$Component);

  function OrderBookComponent(props, context) {
    _classCallCheck(this, OrderBookComponent);

    // default state
    var _this = _possibleConstructorReturn(
      this,
      (
        OrderBookComponent.__proto__ ||
        Object.getPrototypeOf(OrderBookComponent)
      ).call(this, props, context)
    );

    _this.resizeEventListener = function() {
      return _this.onClickCenter(true);
    };

    _this.state = {
      showCenterButton: false //  will change to true when user scrolls away from rest position
    };
    // event handler bindings
    _this.onClickCenter = _this.onClickCenter.bind(_this);
    _this.resizeEventListener = _this.resizeEventListener.bind(_this);
    _this.onScrollRestChange = _this.onScrollRestChange.bind(_this);
    _this.handleScrollRestChange = _this.handleScrollRestChange.bind(_this);

    _this.buyScroller = React.createRef();
    _this.sellScroller = React.createRef();
    return _this;
  }

  _createClass(OrderBookComponent, [
    {
      key: 'onClickCenter',
      value: function onClickCenter() {
        var _this2 = this;

        var overrideUserScrolled =
          arguments.length > 0 && arguments[0] !== undefined
            ? arguments[0]
            : false;

        if (this.sellScroller && this.buyScroller) {
          var scrollToRest = function scrollToRest() {
            _this2.sellScroller.current.scrollToRest();
            _this2.buyScroller.current.scrollToRest();
          };

          if (overrideUserScrolled)
            return this.sellScroller.current.resetUserScrolled(function() {
              return _this2.buyScroller.current.resetUserScrolled(scrollToRest);
            });
          scrollToRest();
        }
      }
    },
    {
      key: 'componentDidUpdate',
      value: function componentDidUpdate(prevProps) {
        this.onClickCenter(
          this.props.selectedInstrumentId !== prevProps.selectedInstrumentId
        );
      }
    },
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        window.addEventListener('resize', this.resizeEventListener);
        this.onClickCenter();
      }
    },
    {
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEventListener);
      }
    },
    {
      key: 'onScrollRestChange',
      value: function onScrollRestChange(value) {
        // show "center" button when user scrolls away from rest position
        setTimeout(this.handleScrollRestChange, 230);
      }
    },
    {
      key: 'handleScrollRestChange',
      value: function handleScrollRestChange() {
        if (!!this.sellScroller.current) {
          var sellIsAtRest = this.sellScroller.current.getIsAtRest();
          var buyIsAtRest = this.buyScroller.current.getIsAtRest();
          var showCenterButton = !sellIsAtRest || !buyIsAtRest;
          this.setState({
            showCenterButton: showCenterButton
          });
        }
      }
    },
    {
      key: 'render',
      value: function render() {
        var context = this.context,
          onScrollRestChange = this.onScrollRestChange,
          _props = this.props,
          spread = _props.spread,
          cancelAllOrders = _props.cancelAllOrders,
          cancelBuyOrders = _props.cancelBuyOrders,
          cancelSellOrders = _props.cancelSellOrders,
          openOrders = _props.openOrders,
          buyOpenOrders = _props.buyOpenOrders,
          sellOpenOrders = _props.sellOpenOrders;
        var _props2 = this.props,
          selectedInstrument = _props2.selectedInstrument,
          cancelOrders = _props2.cancelOrders,
          _props2$level = _props2.level2,
          buy = _props2$level.buy,
          sell = _props2$level.sell,
          buyPriceClicked = _props2.buyPriceClicked,
          buyQuantityClicked = _props2.buyQuantityClicked,
          sellPriceClicked = _props2.sellPriceClicked,
          sellQuantityClicked = _props2.sellQuantityClicked,
          fetching = _props2.fetching,
          hideBulkCancelButtons = _props2.hideBulkCancelButtons;

        buy = buy.reduce((acc, entry, i) => {
          acc.push(
            Object.assign({}, entry, {
              QuantitySum:
                i == 0
                  ? Number(entry.Quantity)
                  : Number(entry.Quantity) + Number(acc[i - 1].QuantitySum)
            })
          );
          return acc;
        }, []);
        sell = sell.reduce(
          (f, entry, i) => acc => {
            acc.push(
              Object.assign({}, entry, {
                QuantitySum:
                  acc.length == 0
                    ? Number(entry.Quantity)
                    : Number(entry.Quantity) +
                      Number(acc[acc.length - 1].QuantitySum)
              })
            );
            return f(acc);
          },
          _ => _
        )([]);
        sell.reverse();

        var CenterButton = function CenterButton(_ref) {
          var state = _ref.state,
            context = _ref.context,
            onClickCenter = _ref.onClickCenter,
            spread = _ref.spread;
          return React.createElement(
            'div',
            { className: orderBookClasses('spread-row') },
            // Requested By Philip , remove the Spread value on the UI
            // React.createElement(
            //   'span',
            //   null,
            //   context.t('Spread'),
            //   ' ',
            //   spread
            // ),
            // Since removed the Spread value, need to make the Center button always ON.
            // state && state.showCenterButton &&
            React.createElement(APInlineButton, {
              icon: 'center',
              iconModifier: 'small',
              text: context.t('Center'),
              onClick: function onClick() {
                return onClickCenter(true);
              },
              customClass: orderBookClasses('center-button')
            })
          );
        };

        return React.createElement(
          'div',
          { className: tradeComponentClasses() },
          React.createElement(
            'div',
            { className: flexTable() + ' ' + orderBookClasses() },
            React.createElement(
              'div',
              { className: flexTable('body') + ' ' + orderBookClasses('body') },
              React.createElement(
                'div',
                {
                  className:
                    flexTable('header', 'background') +
                    ' ' +
                    orderBookClasses('header')
                },
                React.createElement(
                  'div',
                  { className: flexTable('column') },
                  React.createElement(
                    'div',
                    {
                      className:
                        flexTable('fixed') +
                        ' ' +
                        orderBookClasses('table-header', 'price')
                    },
                    context.t('Price')
                  )
                ),
                React.createElement(
                  'div',
                  { className: flexTable('column') },
                  React.createElement(
                    'div',
                    {
                      className:
                        flexTable('fixed') +
                        ' ' +
                        orderBookClasses('table-header', 'qty')
                    },
                    context.t('Qty')
                  )
                ),
                React.createElement(
                  'div',
                  { className: flexTable('column') },
                  React.createElement(
                    'div',
                    {
                      className:
                        flexTable('fixed') +
                        ' ' +
                        orderBookClasses('table-header', 'my-size')
                    },
                    //context.t('My Size')
                    context.t('Sum')
                  )
                )
              ),
              React.createElement(
                'div',
                { className: orderBookClasses('order-book-body') },
                React.createElement(
                  APVerticalScroller,
                  {
                    ref: this.sellScroller,
                    restPosition: 'bottom',
                    onScrollRestChange: onScrollRestChange,
                    customClass: orderBookClasses(
                      'vertical-scroller-body',
                      isFirefox() ? 'firefox' : ''
                    )
                  },
                  React.createElement(
                    'div',
                    { className: 'orderbook__table-filler' },
                    React.createElement(Level2DataTableComponent, {
                      quantityDecimalPlaces: this.props
                        .convertedQuantityIncrement,
                      priceDecimalPlaces: this.props.convertedPriceIncrement,
                      selectedInstrument: selectedInstrument,
                      cancelOrders: cancelOrders,
                      onPriceClick: sellPriceClicked,
                      onQuantityClick: sellQuantityClicked,
                      level2Data: sell,
                      classModifier: 'sell',
                      fetching: fetching
                    })
                  )
                ),
                CenterButton(
                  Object.assign(
                    Object.assign({}, this, {
                      context: context,
                      spread: spread
                    })
                  )
                ),
                React.createElement(
                  APVerticalScroller,
                  {
                    ref: this.buyScroller,
                    restPosition: 'top',
                    onScrollRestChange: onScrollRestChange,
                    customClass: orderBookClasses(
                      'vertical-scroller-body',
                      isFirefox() ? 'firefox' : ''
                    )
                  },
                  React.createElement(Level2DataTableComponent, {
                    quantityDecimalPlaces: this.props
                      .convertedQuantityIncrement,
                    priceDecimalPlaces: this.props.convertedPriceIncrement,
                    selectedInstrument: selectedInstrument,
                    cancelOrders: cancelOrders,
                    onPriceClick: buyPriceClicked,
                    onQuantityClick: buyQuantityClicked,
                    level2Data: buy,
                    classModifier: 'buy',
                    fetching: fetching
                  })
                ),
                !hideBulkCancelButtons &&
                  React.createElement(
                    'div',
                    null,
                    React.createElement(BulkCancelButtonsComponent, {
                      openOrders: openOrders,
                      buyOpenOrders: buyOpenOrders,
                      sellOpenOrders: sellOpenOrders,
                      cancelAllOrders: cancelAllOrders,
                      cancelBuyOrders: cancelBuyOrders,
                      cancelSellOrders: cancelSellOrders
                    })
                  )
              )
            )
          )
        );
      }
    }
  ]);

  return OrderBookComponent;
})(React.Component);

export default OrderBookComponent;

OrderBookComponent.defaultProps = {
  hideBulkCancelButtons: false,
  level2: {
    buy: [],
    sell: [],
    short: [],
    unknown: []
  }
};

OrderBookComponent.contextTypes = {
  t: PropTypes.func.isRequired
};
