import React, { useState, useEffect } from 'react';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { Link } from 'react-router-dom';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { useDigitalAssets } from './DigitalAssetsHooks';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import PropTypes from 'prop-types';
import WelcomeCard from './WelcomeCard';

import './DigitalAssetsPage.css';

const classes = getBEMClasses('digital-assets');

const DigitalAssetsPage = (
  { instruments, selectInstrument, selectedInstrument, unsubscribeLevel2 },
  context
) => {
  const { digitalAssets, digitalAssetsTotals } = useDigitalAssets(
    instruments,
    selectInstrument,
    selectedInstrument,
    unsubscribeLevel2
  );

  // console.log('digitalAssets', digitalAssets);

  const goSTO = () => {
    window.location.href = 'https://blog.cryptosx.io/category/sto-news/';
  };
  // const [digitalAssetsCopy,setDigitalAssets] = useState([])
  // useEffect(() => {
  //   if(digitalAssets.data.length){
  //     setDigitalAssets([...digitalAssets.data])
  //     const sequence = ['ATM','PGI','AMAGOLD','YECO','LOTS','GODL','AGRI','DORE','GINV','AOC','DIGau']
  //     digitalAssetsCopy.forEach((item ,i)=>{
  //       if(sequence.includes(item.Symbol)){
  //         sequence[sequence.indexOf(item.Symbol)] = item

  //         setDigitalAssets([...digitalAssets].slice(i))
  //       }
  //     })
  //   }
  //   console.log(digitalAssetsCopy,'final')
  // }, [])
  // console.log(digitalAssetsTotals , 'weuifweufh')

  // digitalAssetsTotals([...sequence])

  return (
    <React.Fragment>
      <div className={classes('accent-bar')} />
      <div className={classes('container')}>
        {digitalAssets.fetching ? (
          <Spinner />
        ) : digitalAssets.data.length ? (
          <React.Fragment>
            <div className={classes('container-header')}>
              {context.t('Our Selection of Digital Asset Token Offerings')}
            </div>
            <div className={classes('container-STOnews')}>
              <button onClick={() => goSTO()}>
                <p styles={'color:white'}>
                  Read STO NEWS &#128293; &#128293;
                  {/* <a className={classes("__view-more")}  href="https://blog.cryptosx.io/category/sto-news/">Upcoming STO News</a> */}
                </p>
              </button>
            </div>
            <div className={classes('col', ['lg'])}>
              <div className={classes('container-with-shadow')}>
                {digitalAssets.data
                  .filter(asset => asset.Upcoming !== 'true')
                  .map((card, index) => {
                    const total = digitalAssets.data.length
                      ? digitalAssetsTotals.find(
                          total =>
                            total.Product1 === card.ProductId ||
                            total.Product2 === card.ProductId
                        ) || { total: 0 }
                      : { total: 0 };
                    return (
                      <Card
                        {...card}
                        total={total}
                        key={index}
                        selectInstrument={selectInstrument}
                      />
                    );
                  })}
              </div>
            </div>
            <div className={classes('container-header')}>
              {context.t('Upcoming Offerings')}
            </div>
            <div className={classes('col', ['lg'])}>
              <div className={classes('container-with-shadow')}>
                {digitalAssets.data
                  .filter(asset => asset.Upcoming === 'true')
                  .map((card, index) => {
                    const total = digitalAssets.data.length
                      ? digitalAssetsTotals.find(
                          total =>
                            total.Product1 === card.ProductId ||
                            total.Product2 === card.ProductId
                        ) || { total: 0 }
                      : { total: 0 };
                    return <Card2 {...card} total={total} key={index} />;
                  })}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <WelcomeCard />
          // <div className={classes('container-notfound')}>
          //   Looks like you are not subscribed to the Investor Portal, no
          //   offering profiles will be shown here.
          // </div>
        )}
      </div>
      <div className={classes('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

let mapProductId2InstrumentId = {
  ['11']: 22,
  ['10']: 21,
  ['9']: 26,
  ['25']: 32,
  ['30']: 26
};

const Card = (
  {
    Product,
    ProductFullName,
    ProductImageURL,
    ProductDescription,
    ProductId,
    Raising,
    Status,
    total: { total },
    selectInstrument
  },
  context
) => {
  if (Product === 'CCT') {
    return (
      <div className={classes('card')}>
        <div
          className={classes('header-div')}
          style={{ backgroundImage: `url(${ProductImageURL})` }}>
          {' '}
        </div>
        <div className={classes('card-title')}>{ProductFullName}</div>
        <div className={classes('card-description')}>{ProductDescription}</div>
        <div className={classes('details')}>
          <div className={classes('left-detail')}>
            <span className={classes('details-label')}>{Status}</span>
            {/*
          <span className={classes('details-label')}>
            {context.t('Amount of tokens offered:')}
          </span> */}
            <span className={classes('details-value')}>{Raising}</span>
          </div>
          {/* <div className={classes('right-detail')}>
          <span className={classes('details-label')}>Number of Holders:</span>
          <span className={classes('details-value')}>10 Holders</span>
        </div> */}
        </div>
        <div className={classes('card-symbol')}>{Product}</div>
        <div className={classes('card-actions')}>
          <div className={classes('view-more')}>
            {/* <Link
              onClick={event =>
                (window.location.href = 'https://privateswap.io')
              }>
              {context.t('Click here to go to PrivateSwap')} &nbsp;&nbsp;
              <APIcon name="arrowNext" />
            </Link> */}
          </div>
        </div>
      </div>
    );
  } else if (Product === 'FST') {
    return (
      <div className={classes('card')}>
        <div
          className={classes('header-div')}
          style={{ backgroundImage: `url(${ProductImageURL})` }}>
          {' '}
        </div>
        <div className={classes('card-title')}>{ProductFullName}</div>
        <div className={classes('card-description')}>{ProductDescription}</div>
        <div className={classes('details')}>
          <div className={classes('left-detail')}>
            <span className={classes('details-label')}>{Status}</span>
            {/*
          <span className={classes('details-label')}>
            {context.t('Amount of tokens offered:')}
          </span> */}
            <span className={classes('details-value')}>{Raising}</span>
          </div>
          {/* <div className={classes('right-detail')}>
          <span className={classes('details-label')}>Number of Holders:</span>
          <span className={classes('details-value')}>10 Holders</span>
        </div> */}
        </div>
        <div className={classes('card-symbol')}>{Product}</div>
        <div className={classes('card-actions')}>
          <div className={classes('view-more')}>
            <Link
              onClick={event =>
                (window.location.href = 'https://markets.cryptosx.io/dex')
              }>
              {context.t('Click here to go to Cryptosx DEX')} &nbsp;&nbsp;
              <APIcon name="arrowNext" />
            </Link>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className={classes('card')}>
        <div
          className={classes('header-div')}
          style={{ backgroundImage: `url(${ProductImageURL})` }}>
          {' '}
        </div>
        <div className={classes('card-title')}>{ProductFullName}</div>
        <div className={classes('card-description')}>{ProductDescription}</div>
        <div className={classes('details')}>
          <div className={classes('left-detail')}>
            <span className={classes('details-label')}>{Status}</span>
            {/*
          <span className={classes('details-label')}>
            {context.t('Amount of tokens offered:')}
          </span> */}
            <span className={classes('details-value')}>{Raising}</span>
          </div>
          {/* <div className={classes('right-detail')}>
          <span className={classes('details-label')}>Number of Holders:</span>
          <span className={classes('details-value')}>10 Holders</span>
        </div> */}
        </div>
        <div className={classes('card-symbol')}>{Product}</div>
        <div className={classes('card-actions')}>
          <div className={classes('view-more')}>
            <Link
              to={`/digital-assets/${ProductId}`}
              onClick={event =>
                selectInstrument(mapProductId2InstrumentId[ProductId])
              }>
              {context.t('View More')} &nbsp;&nbsp;
              <APIcon name="arrowNext" />
            </Link>
          </div>
        </div>
      </div>
    );
};

const Card2 = (
  {
    Product,
    ProductFullName,
    ProductImageURL,
    ProductDescription,
    ProductId,
    Raising,
    Status,
    total: { total }
  },
  context
) => {
  return (
    <div className={classes('card')}>
      <div
        className={classes('header-div')}
        style={{ backgroundImage: `url(${ProductImageURL})` }}>
        {' '}
      </div>
      <div className={classes('card-title')}>{ProductFullName}</div>
      <div className={classes('card-description')}>{ProductDescription}</div>
      <div className={classes('details')}>
        <div className={classes('left-detail')}>
          <span className={classes('details-label')}>{Status}</span>
          {/*
          <span className={classes('details-label')}>
            {context.t('Amount of tokens offered:')}
          </span> */}
          <span className={classes('details-value')}>{Raising}</span>
        </div>
        {/* <div className={classes('right-detail')}>
          <span className={classes('details-label')}>Number of Holders:</span>
          <span className={classes('details-value')}>10 Holders</span>
        </div> */}
      </div>
      <div className={classes('card-symbol')}>{Product}</div>
      <div className={classes('card-actions')}>
        {/*<div className={classes('view-more')}>
          <Link to={`/digital-assets/${ProductId}`}>
            {context.t('View More')} &nbsp;&nbsp;
            <APIcon name="arrowNext" />
          </Link>
      </div>*/}
      </div>
    </div>
  );
};

DigitalAssetsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

Card.contextTypes = {
  t: PropTypes.func.isRequired
};

Card.defaultProps = {
  ProductImageURL: `http://offering-profiles-samples.surge.sh/noimagefound.png`
};

export default withAuthentication(DigitalAssetsPage);
