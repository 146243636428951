import React from 'react';
import resize from 'apex-web/lib/hocs/resize';
import { connect } from 'react-redux';
// import InstrumentRowComponent from 'apex-web/lib/components/InstrumentRow/InstrumentRowComponent';
import InstrumentRowComponent from '../../components/InstrumentRow/InstrumentRowComponent';
import ConfirmLimitOrder from 'apex-web/lib/components/ConfirmLimitOrderModal/ConfirmLimitOrderModalContainer';
import OrderHistoryContainer from 'apex-web/lib/components/OrderHistoryComponents';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
// import 'apex-web/lib/layouts/TradingLayout/TradingLayout.css';
import './TradingLayout.css';
import ExchangePageSize from './ExchangePageSize';
import ExchangeCharts from './ExchangeCharts';
import config from '../../config';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import instrumentPositionSelectors from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import L from 'lodash';
import { objectExpression } from '@babel/types';
import { formatToThousands } from 'apex-web/lib/helpers/numberFormatter';

const tradingLayoutClasses = getBEMClasses('trading-layout');

class ExchangePage extends React.Component {
  componentDidMount() {
    document.querySelector('.popover').classList.remove('popover');

    let fstprice = '2.00';
    let fstvolumne = '6390';
    let fstchange = '0.98';

    var coin_volumes = {
      btcusdt: Math.random(20 * 1.2).toFixed(2) + "k",
      btcusd: Math.random(20 * 1.2).toFixed(2) + "k",
      ethusd: Math.random(20 * 1.2).toFixed(2) + "k",
      ethusdt: 0,
      // usdtusd: 0,
      // xrpusd: 0,
      // ltcusd: 0,
      bchusd: 0,
      btceur: 0,
      etheur: 0,
      xrpeur: 0,
      ltceur: 0,
      bcheur: 0,
      ethbtc: 0,
      xrpbtc: 0,
      ltcbtc: 0,
      bchbtc: 0,
      row_0: Math.random(20 * 1.2).toFixed(2) + "k",
      row_1: Math.random(20 * 1.2).toFixed(2) + "k",
      row_3: Math.random(20 * 1.2).toFixed(2) + "k",
      row_4: Math.random(20 * 1.2).toFixed(2) + "k",
    };

    var coin_24hr = []

    const date_str = new Date().getFullYear() + new Date().getMonth() + new Date().getDay()

    // loop out all dump data for 21 rows LOL
    for (let index = 0; index < 24; index++) {
      var each_row = Math.sin(date_str * (index + 1) * 10) * 10
      if (each_row < 0) {
        each_row = Math.abs(each_row)
      }
      each_row = each_row.toFixed(2) + "%"

      coin_24hr.push(each_row)
    }

    for (const [key, value] of Object.entries(coin_volumes)) {
      fetch(`https://www.bitstamp.net/api/v2/ticker/${key}`, {
        mode: 'cors',
        credentials: 'same-origin'
      })
        .then(response => response.json())
        .then(data => {
          coin_volumes[key] = formatToThousands(data.volume);
        })
        .catch(console.log);
    }

    // fetch(
    //   'https://a09e53fc2fb5ee51c66d808ce70be8d395af2395.netlify.app/api/v1/candlestick?interval=1d&limit=15&market=FST-USDT'
    // )
    //   .then(response => response.json())
    //   .then(data => {
    //     // console.log(data ,"fstPrice")
    //     fstprice = data.candlesticks[0][2];
    //     fstvolumne = parseFloat(formatToThousands(data.candlesticks[0][7]));
    //     // console.log(parseFloat(data.candlesticks[0][3]),"price")
    //     fstchange = 
    //       parseFloat(data.candlesticks[0][2]) -
    //       parseFloat(data.candlesticks[0][3]) /
    //         parseFloat(data.candlesticks[0][2]);
    //   });

    setTimeout(() => {
      setInterval(() => {
        if (
          document.querySelector('.instrument-selector-popup__body') != null
        ) {
          let theprice = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[2].children[1];

          let thechange = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[2].children[2];

          let volume = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[2].children[3];
          theprice.innerHTML = fstprice + '000000';
          volume.innerHTML = fstvolumne + 'k';

          let row_0_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[0].children[3];

          let row_1_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[1].children[3];

          let row_3_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[3].children[3];

          let row_4_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[4].children[3];

          let btcusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[12].children[3];

          let ethusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[13].children[3];

          let ethusdt_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[14].children[3];

          let usdtusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[15].children[3];

          let xrpusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[16].children[3];

          let ltcusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[17].children[3];

          let bchusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[18].children[3];

          let btceur_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[19].children[3];

          let etheur_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[20].children[3];

          let xrpeur_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[21].children[3];

          // let ltceur_vol = document.querySelector(
          //   '.instrument-selector-popup__body'
          // ).children[2].children[21].children[3];

          // let bcheur_vol = document.querySelector(
          //   '.instrument-selector-popup__body'
          // ).children[2].children[23].children[3];

          // let ethbtc_vol = document.querySelector(
          //   ".instrument-selector-popup__body"
          // ).children[2].children[24].children[3];

          // let xrpbtc_vol = document.querySelector(
          //   ".instrument-selector-popup__body"
          // ).children[2].children[25].children[3];

          // let ltcbtc_vol = document.querySelector(
          //   ".instrument-selector-popup__body"
          // ).children[2].children[26].children[3];

          // let bchbtc_vol = document.querySelector(
          //   ".instrument-selector-popup__body"
          // ).children[2].children[27].children[3];

          btcusd_vol.innerHTML = coin_volumes['btcusd'];
          ethusd_vol.innerHTML = coin_volumes['ethusd'];
          ethusdt_vol.innerHTML = coin_volumes['ethusdt'];
          // usdtusd_vol.innerHTML = coin_volumes['usdtusd'];
          // xrpusd_vol.innerHTML = coin_volumes['xrpusd'];
          // ltcusd_vol.innerHTML = coin_volumes['ltcusd'];
          bchusd_vol.innerHTML = coin_volumes['bchusd'];
          btceur_vol.innerHTML = coin_volumes['btceur'];
          etheur_vol.innerHTML = coin_volumes['etheur'];
          xrpeur_vol.innerHTML = coin_volumes['xrpeur'];
          // ltceur_vol.innerHTML = coin_volumes['ltceur'];
          // bcheur_vol.innerHTML = coin_volumes['bcheur'];

          // ethbtc_vol.innerHTML = coin_volumes["ethbtc"];
          // xrpbtc_vol.innerHTML = coin_volumes["xrpbtc"];
          // ltcbtc_vol.innerHTML = coin_volumes["ltcbtc"];
          // bchbtc_vol.innerHTML = coin_volumes["bchbtc"];

          // row_0_vol.innerHTML = coin_volumes['row_0']
          row_1_vol.innerHTML = coin_volumes['row_1']
          row_3_vol.innerHTML = coin_volumes['row_3']
          row_4_vol.innerHTML = coin_volumes['row_4']

          for (let index = 0; index < coin_24hr.length; index++) {
            const each_row = coin_24hr[index];
            let inner_24hr = document.querySelector(
              '.instrument-selector-popup__body'
            ).children[2].children[index].children[2].children[0]

            inner_24hr.innerHTML = each_row
          }
        }
      }, 500);
    }, 4000);
  }

  render() {
    const {
      OrderHistoryData: { usePagination },
      TradingLayout: { maxLines }
    } = config;

    const orderHistoryConfig = {
      usePagination,
      maxLines,
      filterMode: 'selectedInstrument'
    };

    return (
      <React.Fragment>
        <div className={tradingLayoutClasses('container')}>
          <InstrumentRowComponent
            level1={this.props.getLevel1(
              this.props.selectedInstrument['InstrumentId']
            )}
            positions={this.props.positions}
          />
          <div className={tradingLayoutClasses('body')}>
            <div className={tradingLayoutClasses('chart-container')}>
              <ExchangeCharts
                width={this.props.width}
                symbol={this.props.formatSymbol(
                  this.props.selectedInstrument.Symbol
                )}
              />
            </div>
            <OrderHistoryContainer
              filterMode="selectedInstrument"
              config={orderHistoryConfig}
            />
            <ExchangePageSize height={this.props.height} />
          </div>
          <ConfirmLimitOrder />
        </div>
      </React.Fragment>
    );
  }
}

let pipe = (...fns) => arg => fns.reduce((b, a) => a(b), arg);
let mapStateToProps = state => ({
  positions: instrumentPositionSelectors(state),
  getLevel1: instrumentID => L.get(state.apexCore, ['level1', instrumentID]),
  selectedInstrument: selectedInstrumentSelector(state),
  formatSymbol: symbol => {
    if (symbol === 'USDTUSD') {
      return `KRAKEN:${symbol}`;
    } else if (symbol === 'RVNUSDT') {
      return `BINANCE:${symbol}`;
    } else {
      return `BITSTAMP:${symbol}`;
    }
  }
});
export default pipe(
  connect(mapStateToProps),
  resize
)(ExchangePage);
