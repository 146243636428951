import { connect } from 'react-redux';
import { change } from 'redux-form';
import { standardInstrumentsWithProductFullNamesAndProductSymbols } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import { unsubscribeLevel2 } from 'apex-web/lib/redux/actions/level2Actions';
import { openSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import DigitalAssetsOfferingProfilePage from './DigitalAssetsOfferingProfilePage';
import get from 'lodash/get';

const mapStateToProps = state => {
  return {
    price: get(
      state,
      [
        'apexCore',
        'level1',
        state.apexCore.instrument.selectedInstrumentId,
        'BestOffer'
      ],
      0
    ),
    instruments: standardInstrumentsWithProductFullNamesAndProductSymbols(
      state
    ),
    selectedInstrument: state.apexCore.instrument.selectedInstrumentId
  };
};

const mapDispatchToProps = dispatch => ({
  openSendReceiveSidePane: (product, isBuySide) => {
    const config = { hideHeader: true, product };
    dispatch(openSidePane('AdvancedOrder', config));

    // Update form after it gets initialized by component mounting.
    setTimeout(() => {
      dispatch(change('advancedOrderEntry', 'side', isBuySide));
    }, 1);
  },
  selectInstrument: payload =>
    dispatch(selectInstrument(parseInt(payload, 10))),
  unsubscribeLevel2: instrumentId => dispatch(unsubscribeLevel2(instrumentId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DigitalAssetsOfferingProfilePage);
