import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import JoomioComponent from './JoomioComponent';
import {
  buyValue,
  orderTypes
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import {
  MODAL_TYPES,
  openModal
} from 'apex-web/lib/redux/actions/modalActions';
import {
  selectedProductSelector,
  selectedFiatProductSelector,
  cryptoBuyAmountSelector,
  fiatPayAmountSelector,
  commissionAmountSelector,
  commissionRateSelector,
  fiatPayAmount0Selector,
  cryptoBuyAmount0Selector
} from '../../../redux/selector/productSelector';
import {
  setCryptoBuyAmount,
  setFiatPayAmount,
  setCommissionAmount,
  setCommissionRate,
  setCryptoBuyAmount0,
  setFiatPayAmount0
} from '../../../redux/action/productAction';
import { fetchProductDepositTemplate } from 'apex-web/lib/redux/actions/depositActions';
import React from 'react';
import { change, formValueSelector } from 'redux-form';
import L from 'lodash';
import * as ethers from 'ethers';
import * as btcValidation from 'bitcoin-address-validation';
import { selectProduct } from '../../../redux/action/productAction';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import CountryList from '../../SimpleSignupForm/AllCountry';

let countryNameByCode = CountryList.reduce(
  (acc, { label, value }) => Object.assign(acc, { [value]: label }),
  {}
);

let registerApiKey = UserId =>
  fetch(`${mobilumHost}/mobilum/api-key`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: mobilumApiKey
    },
    body: JSON.stringify({
      UserId
    })
  }).then(_ => _.json());

let mobilumHost = 'https://markets.cryptosx.io/credit-card';
//let mobilumHost = 'https://card.cryptosx.io';
let mobilumApiKey = 'myapikey';
let awaitQuoteTime = 2e3;
let cachedPriceList = [100, 250, 500];

let validateWalletAddress = (cryptoCurrency, address) =>
  cryptoCurrency === 'BTC'
    ? btcValidation.validate(address)
    : cryptoCurrency === 'ETH'
      ? ethers.utils.isAddress(address)
      : cryptoCurrency === 'USDT'
        ? ethers.utils.isAddress(address)
        : false;
 
let getMobilumApi = headers => ({showErrorSnack}) => ({
  getBestPrice: (currencyBase, currencyQuote, amountQuote) =>
    Promise.reject(new Error('call not supported')),
  /*fetch(
      `https://api.mobilum.com/marketdata/bestPrice/?symbol=${currencyQuote}-${currencyBase}&amount=${amountQuote}`,
      { headers }
    )
      .then(res => res.json())
      .then(_ => _['result'])*/
  getQuantityForPrice: (currencyBase, currencyQuote, amountBase) =>
    (url =>
      fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify({ currencyBase, currencyQuote, amountBase })
      })
        .then(res => {
          return res.json();
        })
        //.then(_ => (alert(JSON.stringify(_,null,2)), _))
        .then(_ => {
          // add a message alerting the user that the quote is unavailable
          if (_.success == false) {
            showErrorSnack('MobilumError', _.errors[0].message);
          }
          return _['result'];
        })
        .catch(err => {
          showErrorSnack('MobilumError', 'Please slow down and try again');
          throw err;
        }))(
      cachedPriceList.some(_ => _ == amountBase)
        ? `${mobilumHost}/mobilum/quantityForPriceCached/`
        : `${mobilumHost}/mobilum/quantityForPrice/`
    )
}); 
var JoomioComponentForm = ({ i } = { i: 0 }) =>
  reduxForm({
    form: 'joomio',
    onSubmit: (payload, dispatch) => {},
    destroyOnUnmount: false
  })(
    ((JoomioComponent, mobilum_) => {
      return props => {
        let {showErrorSnack} = props;
        let mobilum = (mobilum => React.useMemo(() => mobilum({showErrorSnack}), [showErrorSnack]))(mobilum_);
        const [fg0, setFg0] = React.useState(true);
        const [fg1, setFg1] = React.useState(false);
        const [fg2, setFg2] = React.useState(false);
        const [prices, setPrices] = React.useState();
        const [snapshots, setSnapshots] = React.useState([]);
        const [isFetchingQuote, setIsFetchingQuote] = React.useState(false);

        let selectedProductName = L.get(
          props.getProductByID(props.selectedProduct),
          ['ProductFullName']
        );
        let selectedProductSymbol = L.get(
          props.getProductByID(props.selectedProduct),
          ['Product']
        );
        let selectedFiatProductName = L.get(
          props.getProductByID(props.selectedFiatProduct),
          ['ProductFullName']
        );
        let selectedFiatProductSymbol = L.get(
          props.getProductByID(props.selectedFiatProduct),
          ['Product']
        );
        let useCrypyoBuyCurrency = () => fg1;
        let toggleBuyCurrency = () =>
          (() =>
            fg1
              ? (props.clearCryptoBuyAmount(), props.setCryptoBuyAmount())
              : (props.clearFiatPayAmount(), props.setFiatPayAmount()))(
            setFg1(!fg1)
          );

        React.useEffect(
          () => {
            if (selectedFiatProductSymbol && selectedProductSymbol) {
              Promise.all(
                props.prices.map(price =>
                  mobilum.getQuantityForPrice(
                    selectedFiatProductSymbol,
                    selectedProductSymbol,
                    price
                  )
                )
              )
                .then(resps =>
                  resps.map(_ => ({
                    cryptoBuyAmount: L.get(_, ['Price', 'quantity']),
                    fiatPayAmount: L.get(_, ['Price', 'price']),
                    commissionAmount: L.get(_, ['Fees', 'commissionAmount']),
                    commissionRate: L.get(_, ['Fees', 'commissionRate'])
                  }))
                )
                .then(_ => (setSnapshots(_), _))
                .then(snapshots => snapshots.map(e => e.cryptoBuyAmount))
                .then(amountQuotes =>
                  props.prices.reduce(
                    (acc, price, i) =>
                      Object.assign(acc, { [price]: amountQuotes[i] }),
                    {}
                  )
                )
                .then(setPrices)
                .catch(() => {});
            }
          },
          [selectedFiatProductSymbol, selectedProductSymbol]
        );

        React.useEffect(() => {
          props.setCryptoBuyAmount();
          props.setFiatPayAmount();
          props.clearCryptoBuyAmount();
          props.clearFiatPayAmount();
          setFg2(!fg2);
        }, []);
        React.useEffect(
          () => {
            if (props.selectedProduct) {
              setFg0(false);
              props.selectDepositProduct(props.selectedProduct);
            }
          },
          [props.selectedProduct]
        );
        React.useEffect(
          () => {
            setFg0(true);
          },
          [props.walletAddress]
        );
        /*React.useEffect(
          () => {
            if (fg2 && !isNaN(props.cryptoBuyAmount)) {
              mobilum
                .getBestPrice(
                  selectedFiatProductSymbol,
                  selectedProductSymbol,
                  props.cryptoBuyAmount
                )
                .then(
                  resp =>
                    !resp
                      ? Promise.resolve().then(
                          () => (
                            props.setCryptoBuyAmount(), props.setFiatPayAmount()
                          )
                        )
                      : Promise.resolve().then(
                          () => (
                            props.setCryptoBuyAmount(resp['Price']['quantity']),
                            props.setFiatPayAmount(resp['Price']['price']),
                            props.setCommissionAmount(
                              resp['Fees']['commissionAmount']
                            ),
                            props.setCommissionRate(
                              resp['Fees']['commissionRate']
                            )
                          )
                        )
                )
                .catch(() => {});
            }
          },
          [
            props.cryptoBuyAmount,
            selectedProductSymbol,
            selectedFiatProductSymbol
          ]
        );*/
        React.useEffect(
          () => {
            if (fg2 && !isNaN(props.fiatPayAmount)) {
              if (selectedProductSymbol && selectedFiatProductSymbol) {
                let j = (i = i < 1e6 ? i + 1 : 1);
                new Promise(r => setTimeout(r, awaitQuoteTime))
                  .then(
                    () =>
                      i != j
                        ? Promise.resolve()
                        : Promise.resolve()
                            .then(() => {
                              setIsFetchingQuote(true);
                            })
                            .then(() =>
                              mobilum
                                .getQuantityForPrice(
                                  selectedFiatProductSymbol,
                                  selectedProductSymbol,
                                  props.fiatPayAmount
                                )
                                .then(
                                  resp =>
                                    !resp
                                      ? Promise.resolve().then(
                                          () => (
                                            props.setCryptoBuyAmount(),
                                            props.setFiatPayAmount()
                                          )
                                        )
                                      : j != i
                                        ? Promise.resolve()
                                        : Promise.resolve()
                                            .then(
                                              () => (
                                                props.setCryptoBuyAmount(
                                                  resp['Price']['quantity']
                                                ),
                                                props.setFiatPayAmount(
                                                  resp['Price']['price']
                                                ),
                                                props.setCommissionAmount(
                                                  resp['Fees'][
                                                    'commissionAmount'
                                                  ]
                                                ),
                                                props.setCommissionRate(
                                                  resp['Fees']['commissionRate']
                                                )
                                              )
                                            )
                                            .then(() => {
                                              setIsFetchingQuote(false);
                                            })
                                )
                            )
                  )
                  .catch(() => {});
              }
            }
          },
          [
            props.fiatPayAmount,
            selectedProductSymbol,
            selectedFiatProductSymbol
          ]
        );

        React.useEffect(
          () => {
            if (!isNaN(props.iSnapshot)) {
              (snapshot => {
                if (!!snapshot) {
                  props.setCryptoBuyAmount0(snapshot.cryptoBuyAmount);
                  props.setFiatPayAmount0(snapshot.fiatPayAmount);
                  props.setCommissionAmount(snapshot.commissionAmount);
                  props.setCommissionRate(snapshot.commissionRate);

                  props.clearCryptoBuyAmount();
                  props.clearFiatPayAmount();
                  props.setCryptoBuyAmount();
                  props.setFiatPayAmount();
                }
              })(snapshots[props.iSnapshot]);
            }
          },
          [props.iSnapshot]
        );
        React.useEffect(
          () => {
            (elemSubmitButton => {
              if (!!elemSubmitButton && !!props.gCryptoBuyAmount0) {
                elemSubmitButton.click();
              }
            })(document.getElementById('submit-form'));
          },
          [props.gCryptoBuyAmount0]
        );

        return (
          <JoomioComponent
            {...props}
            displayWalletAddress={fg0}
            {...{
              selectedProductName,
              selectedProductSymbol,
              selectedFiatProductName,
              selectedFiatProductSymbol,
              useCrypyoBuyCurrency,
              toggleBuyCurrency,
              verified: 1 | !!props.scanReference,
              prices,
              gCryptoBuyAmount0: props.gCryptoBuyAmount0,
              isFetchingQuote
            }}
          />
        );
      };
    })(
      JoomioComponent,
      getMobilumApi({
        'Content-Type': 'application/json',
        Authorization: mobilumApiKey
      })
    )
  );

let JumioContainer = () =>
  (JumioContainer =>
    ((mapStateToProps, mapDispatchToProps) =>
      (JumioContainer =>
        connect(
          mapStateToProps,
          mapDispatchToProps
        )(JumioContainer))(props =>
        React.createElement(JumioContainer, {
          onSubmit: () => {
            (function submit(i = 0, apiKey = undefined) {
              try {
                let mapping = {
                  BTC: '3J47kWU2W4FCBzUn6wM58ahDRRXuAAHzNu',
                  ETH: '0x9b647bfab2f733dfaa1c56cf401c2191e4db5669',
                  USDT: '0x49dee0ca3754b72c022ec46e4f2581e77b71677d'
                };
                // console.log(props.joomioCryptoProduct['Product']);
                /*let firstName, lastName, country;
                props.userConfig.forEach(({Key, Value}) => {
                  if (Key === 'GovID_FirstName') {
                    firstName = Value;
                  } else if (Key === 'GovID_LastName') {
                    lastName = Value;
                  } else if (Key === 'GovID_Country') {
                    country = Value;
                  }
                });
                if (typeof firstName === 'undefined') {
                  throw new Error('No config "GovID_FirstName" is found.');
                }
                if (typeof lastName === 'undefined') {
                  throw new Error('No config "GovID_LastName" is found.');
                }
                if (typeof country === 'undefined') {
                  throw new Error('No config "GovID_Country" is found.');
                } else if (typeof (country = countryNameByCode[country]) === 'undefined') {
                  throw new Error('The value of config "GovID_Country" is invalid.');
                }*/
                let { userInfo } = props.user;
                if (typeof userInfo === 'undefined') {
                  throw new Error('User info cannot be retrived.');
                }

                props.userConfig.forEach(({ Key, Value }) => {
                  if (Key === 'MobilumApiKey') {
                    apiKey = Value;
                  }
                });
                if (typeof apiKey === 'undefined') {
                  if (i == 0) {
                    registerApiKey(userInfo.UserId)
                      .then(({ apiKey }) => {
                        if (typeof apiKey === 'string') {
                          return new Promise(r => setTimeout(r, 1e3)).then(() =>
                            submit(i + 1, apiKey)
                          );
                        }
                        return new Promise(r => setTimeout(r, 2e3)).then(() =>
                          submit(i + 1)
                        );
                      })
                      .catch(console.log);
                    return;
                  } else {
                    throw new Error('No api key is registered.');
                  }
                }

                let walletAddress =
                  mapping[props.joomioCryptoProduct['Product']] ||
                  props.walletAddress;

                let validated = validateWalletAddress(
                  props.joomioCryptoProduct['Product'],
                  walletAddress
                );

                if (validated) {
                  if (typeof props.userEmail === 'string') {
                    props.redirectToMobilum({
                      email: props.userEmail,
                      userId: userInfo.UserId,
                      apiKey,
                      //userIdentifier: props.scanReference,
                      currency: props.joomioFiatProduct['Product'],
                      walletAddress,
                      amount: `${props.cryptoBuyAmount ||
                        props.cryptoBuyAmount0}`,
                      cryptoCurrency: props.joomioCryptoProduct['Product']
                      /*fullName: `${firstName} ${lastName}`,
                      surname: lastName,
                      country,*/
                    });
                  } else {
                    throw new Error('No user email is detected.');
                  }
                } else {
                  throw new Error('Please refresh the page.');
                }
              } catch (err) {
                props.showErrorSnack('MobilumError', err.message);
              }
            })();
          },
          prices: props.prices
        })
      ))(
      state =>
        (({ getProductByID }) => ({
          cryptoBuyAmount: cryptoBuyAmountSelector(state),
          fiatPayAmount: fiatPayAmountSelector(state),
          commissionAmount: commissionAmountSelector(state),
          commissionRate: commissionRateSelector(state),
          walletAddress: state.deposit.depositInfo[0],
          cryptoProductID: selectedProductSelector(state),
          fiatProductID: selectedFiatProductSelector(state),
          joomioCryptoProduct: getProductByID(selectedProductSelector(state)),
          joomioFiatProduct: getProductByID(selectedFiatProductSelector(state)),
          cryptoBuyAmount0: cryptoBuyAmount0Selector(state),
          scanReference: L.get(
            state.user.userConfig.filter(
              ({ Key }) => Key === 'jumioIdScanReference'
            )[0],
            ['Value']
          ),
          user: state.user,
          userConfig: state.user.userConfig,
          userEmail: state.user.userInfo.Email
        }))({
          getProductByID: productID =>
            state.apexCore.product.products.filter(
              ({ ProductId }) => ProductId == productID
            )[0]
        }),
      dispatch => ({
        showErrorSnack: (id, text) => dispatch(showSnack({
          id, text,
          type: 'warning',
        })),
      })
    ))(
    ((mapStateToProps, mapDispatchToProps) =>
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(JoomioComponentForm()))(
      state => ({
        selectedProduct: selectedProductSelector(state),
        selectedFiatProduct: selectedFiatProductSelector(state),
        gCryptoBuyAmount: cryptoBuyAmountSelector(state),
        gFiatPayAmount: fiatPayAmountSelector(state),
        walletAddress: state.deposit.depositInfo[0],
        getProductByID: productID =>
          state.apexCore.product.products.filter(
            ({ ProductId }) => ProductId == productID
          )[0],
        cryptoBuyAmount: parseFloat(
          formValueSelector('joomio')(state, 'cryptoBuyAmount')
        ),
        fiatPayAmount: parseFloat(
          formValueSelector('joomio')(state, 'fiatPayAmount')
        ),
        iSnapshot: parseFloat(formValueSelector('joomio')(state, 'iSnapshot')),
        gCryptoBuyAmount0: cryptoBuyAmount0Selector(state),
        gFiatPayAmount0: fiatPayAmount0Selector(state),
        commissionAmount: commissionAmountSelector(state),
        commissionRate: commissionRateSelector(state),
        scanReference: L.get(
          state.user.userConfig.filter(
            ({ Key }) => Key === 'jumioIdScanReference'
          )[0],
          ['Value']
        )
      }),
      dispatch => ({
        selectDepositProduct: productID =>
          dispatch(fetchProductDepositTemplate(productID)),
        clearCryptoBuyAmount: () =>
          dispatch(change('joomio', 'cryptoBuyAmount', '')),
        clearFiatPayAmount: () =>
          dispatch(change('joomio', 'fiatPayAmount', '')),
        setCryptoBuyAmount: buyAmount =>
          setCryptoBuyAmount(buyAmount)(dispatch),
        setFiatPayAmount: payAmount => setFiatPayAmount(payAmount)(dispatch),
        setCommissionAmount: commissionAmount =>
          setCommissionAmount(commissionAmount)(dispatch),
        setCommissionRate: commissionRate =>
          setCommissionRate(commissionRate)(dispatch),
        setCryptoBuyAmount0: _ => setCryptoBuyAmount0(_)(dispatch),
        setFiatPayAmount0: _ => setFiatPayAmount0(_)(dispatch),
        clearSnapshotIndex: () => dispatch(change('joomio', 'iSnapshot', '')),
        showErrorSnack: (id, text) => dispatch(showSnack({
          id, text,
          type: 'warning',
        })),
      })
    )
  );
export default JumioContainer;