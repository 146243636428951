import { connect } from 'react-redux';
import { standardInstrumentsWithProductFullNamesAndProductSymbols } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import { unsubscribeLevel2 } from 'apex-web/lib/redux/actions/level2Actions';
import DigitalAssetsPage from './DigitalAssetsPage';

const mapStateToProps = state => {
  return {
    instruments: standardInstrumentsWithProductFullNamesAndProductSymbols(
      state
    ),
    selectedInstrument: state.apexCore.instrument.selectedInstrumentId
  };
};

const mapDispatchToProps = dispatch => ({
  selectInstrument: payload =>
    dispatch(selectInstrument(parseInt(payload, 10))),
  unsubscribeLevel2: instrumentId => dispatch(unsubscribeLevel2(instrumentId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DigitalAssetsPage);
