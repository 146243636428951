import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  getFinalBalanceWithdrawValue,
  getAvailableBalance
} from 'apex-web/lib/helpers/withdrawHelper';
import {
  FiatWithdrawModalContainer,
  FiatWithdrawFormContainer,
  FiatWithdrawDetailsComponent
} from './FiatWithdraw';
import {
  FiatDepositFormContainer,
  FiatDepositModalContainer,
  FiatDepositDetailsComponent
} from './FiatDeposit';
import FiatSidePaneHeaderComponent from './FiatSidePaneHeaderComponent';
import FiatFormTabsComponent from './FiatFormTabsComponent';
import './FiatSidePaneComponent.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var fiatSidePaneClasses = getBEMClasses('fiat-sidepane');

var FiatSidePaneComponent = (function(_React$Component) {
  _inherits(FiatSidePaneComponent, _React$Component);

  function FiatSidePaneComponent(props) {
    _classCallCheck(this, FiatSidePaneComponent);

    var _this = _possibleConstructorReturn(
      this,
      (
        FiatSidePaneComponent.__proto__ ||
        Object.getPrototypeOf(FiatSidePaneComponent)
      ).call(this, props)
    );

    _this.onTabChange = function() {
      _this.setState({
        isSend: !_this.state.isSend,
        data: {}
      });
    };

    _this.onUpdate = function() {
      _this.getWithdrawFee();
      _this.setTotal();
    };

    _this.setTotal = function() {
      var _this$props = _this.props,
        position = _this$props.position,
        fee = _this$props.fee;
      var Amount = _this.state.data.Amount;

      var amount = parseFloat(Amount);
      var balance = getAvailableBalance(position.Amount, position.Hold);
      var total = 0;

      if (amount) {
        total = getFinalBalanceWithdrawValue(balance, amount, fee);
      }

      _this.setState({ total: total });
    };

    _this.getWithdrawFee = function() {
      var _this$props2 = _this.props,
        ProductId = _this$props2.product.ProductId,
        getWithdrawFee = _this$props2.getWithdrawFee;
      var Amount = _this.state.data.Amount;

      if (!isNaN(Amount)) {
        getWithdrawFee(ProductId, Amount);
      }
    };

    _this.onDataUpdate = function(data) {
      var _this$state = _this.state,
        Amount = _this$state.data.Amount,
        isSend = _this$state.isSend;

      _this.setState({ data: data }, function() {
        if (!isSend && data.Amount !== Amount) {
          _this.onUpdate();
        }
      });
    };

    _this.renderInstructions = function(modificator) {
      return React.createElement(
        'section',
        { className: fiatSidePaneClasses('side-details', modificator) },
        React.createElement(
          'header',
          {
            className: fiatSidePaneClasses('instructions-header', modificator)
          },
          _this.context.t('Instructions')
        ),
        React.createElement(
          'section',
          {
            className: fiatSidePaneClasses('address-instructions', modificator)
          },
          React.createElement(
            'div',
            null,
            _this.context.t('Step 1: Create the deposit ticket.')
          ),
          React.createElement(
            'div',
            null,
            _this.context.t(
              'Step 2: Process deposit instructions on the deposit receipt.'
            )
          )
        )
      );
    };

    _this.state = {
      isSend: props.isSend,
      data: {},
      openConfirmModal: false
    };
    return _this;
  }

  _createClass(FiatSidePaneComponent, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.props.onSidePaneOpen({
          customClass: this.props.showDetails
            ? 'retail-sidepane-with-details'
            : 'retail-sidepane',
          classModifiers: 'with-tabs',
          hideHeader: true
        });
      }
    },
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          TicketAmount = _props.TicketAmount,
          product = _props.product,
          position = _props.position,
          fee = _props.fee,
          showDetails = _props.showDetails,
          productConfig = _props.productConfig,
          withdrawDetailsInfo = _props.withdrawDetailsInfo,
          isDepositDisabled = _props.isDepositDisabled,
          isWithdrawDisabled = _props.isWithdrawDisabled;
        var _state = this.state,
          isSend = _state.isSend,
          data = _state.data,
          total = _state.total;

        var balance = getAvailableBalance(position.Amount, position.Hold);

        return React.createElement(
          'div',
          { className: fiatSidePaneClasses() },
          React.createElement(
            'section',
            { className: fiatSidePaneClasses('main') },
            React.createElement(FiatSidePaneHeaderComponent, {
              product: product
            }),
            React.createElement(
              'section',
              { className: fiatSidePaneClasses('main-form') },
              React.createElement(FiatFormTabsComponent, {
                isSend: isSend,
                toggleTab: this.onTabChange,
                product: product,
                balance: balance,
                isDepositDisabled: isDepositDisabled,
                isWithdrawDisabled: isWithdrawDisabled
              }),
              isSend
                ? React.createElement(FiatDepositFormContainer, {
                    product: product,
                    onChange: this.onDataUpdate
                  })
                : React.createElement(FiatWithdrawFormContainer, {
                    product: product,
                    balance: balance,
                    fee: fee,
                    total: total,
                    onChange: this.onDataUpdate,
                    showDetails: showDetails
                  })
            ),
            isSend && !showDetails
              ? this.renderInstructions('no-details')
              : null
          ),
          showDetails &&
            React.createElement(
              'section',
              { className: fiatSidePaneClasses('side') },
              isSend
                ? React.createElement(
                    (C => () =>
                      React.createElement(
                        'div',
                        {
                          className:
                            'sidepane-details__information-text sidepane-details__information-text--deposit'
                        },
                        this.context.t('Suspend Notice')
                      ))(FiatDepositDetailsComponent),
                    { info: productConfig.instructions }
                  )
                : React.createElement(FiatWithdrawDetailsComponent, {
                    TicketAmount: TicketAmount,
                    data: data,
                    fee: fee,
                    product: product,
                    balance: balance,
                    detailsInfo: withdrawDetailsInfo
                  })
            ),
          React.createElement(FiatDepositModalContainer, null),
          React.createElement(FiatWithdrawModalContainer, null)
        );
      }
    }
  ]);

  return FiatSidePaneComponent;
})(React.Component);

FiatSidePaneComponent.defaultProps = {
  showDetails: true,
  onSidePaneOpen: function onSidePaneOpen() {},
  isSend: true
};

FiatSidePaneComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatSidePaneComponent;
