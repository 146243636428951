import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectAccount as _selectAccount } from 'apex-web/lib/redux/actions/userActions';
import { useTranslation } from 'react-i18next';
import {
  marginAccountSelector,
  defaultAccountSelector
} from 'apex-web/lib/redux/selectors/marginSelectors';
import { logout as _logout } from 'apex-web/lib/redux/actions/authActions';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  Image,
  NavItem,
  IconButton,
  IconButtonMb,
  Caret,
  Help,
  App,
  Dropdown,
  Dropdown2,
  Dropdown3,
  DropdownMb,
  DropdownMenuItem,
  DropdownMenuItemLink,
  DropdownMenuIcon,
  DropdownMenuIconRight,
  DropdownMenuItemSpan
} from './NavbarElements';
import Logo from '../../images/custom/CryptosX_AnimatedLogo.svg';
import {
  FaChartBar,
  FaRegChartBar,
  FaLandmark,
  FaLayerGroup,
  FaUser,
  FaShareSquare,
  FaUserShield,
  FaSearch,
  FaMoneyBill,
  FaUserCog,
  FaTable,
  FaWallet,
  FaRandom,
  FaRegCreditCard
} from 'react-icons/fa';
import { noDuplicateAccountsSelector } from '../../redux/selector/noDuplicateAccountSelector';

var mapStateToProps = function mapStateToProps(state) {
  var selectedAccountId = state.user.selectedAccountId;
  // console.log("selectedAccountId", selectedAccountId)
  var marginAccount = marginAccountSelector(state);
  var accounts = noDuplicateAccountsSelector(state);
  var selectedAccount = accounts.find(function(account) {
    return account.AccountId === selectedAccountId;
  });
  if (!selectedAccount && marginAccount) {
    selectedAccount = defaultAccountSelector(state);
  }
  var selectableAccounts =
    selectedAccount === undefined
      ? accounts
      : accounts.filter(a => a.AccountId !== selectedAccount.AccountId);
  return {
    accounts: accounts,
    selectableAccounts: selectableAccounts,
    selectedAccount: selectedAccount
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    logout: function logout() {
      console.log('dispatch logout');
      return dispatch(_logout());
    },
    selectAccount: function selectAccount(payload) {
      // payload is an account
      let data = {
        AccountId: payload.AccountId,
        OMSId: payload.OMSID
      };
      return dispatch(_selectAccount(payload.AccountId, payload.OMSID));
    }
  };
};

const _Navbar = props => {
  const [t, i18n] = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openMb, setOpenMb] = useState(false);
  const updatelanguageZH = updatedData => {
    const profile = localStorage.getItem('language');
    localStorage.setItem('language', 'zh');
    window.location.reload();
  };

  const updatelanguageEN = updatedData => {
    const profile = localStorage.getItem('language');
    localStorage.setItem('language', 'en');
    window.location.reload();
  };
  useEffect(() => {
    const language = localStorage.getItem('language');
    console.log('language', language);
    if (language === 'zh') {
      i18n.changeLanguage('zh');
    } else if (language === 'en') {
      i18n.changeLanguage('en');
    }
  }, []);
  const closeAllMenu = () => {
    // i18n.changeLanguage('zh');
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpenMb(false);
  };
  return (
    <React.Fragment>
      <Nav>
        <Image href="https://cryptosx.io">
          <object
            id="animation"
            type="image/svg+xml"
            data={Logo}
            alt="logo"
            style={{ width: '200px' }}
          />
        </Image>
        <IconButtonMb onClick={() => setOpenMb(!openMb)}>
          <Bars />
        </IconButtonMb>
        <NavMenu>
          <NavLink to="/digital-assets" onClick={closeAllMenu}>
            {t('navBar.Digital Assets')}
          </NavLink>
          <NavLink to="/Wallets" onClick={closeAllMenu}>
            {t('navBar.wallets')}
          </NavLink>
          <NavLink to="/credit-card" onClick={closeAllMenu}>
            {t('navBar.creditcard')}
          </NavLink>
          <NavLink to="/settings/verification-level" onClick={closeAllMenu}>
            {t('navBar.Profile/KYC')}

            {/* Profile/KYC */}
          </NavLink>
          <NavLink to="/exchange" onClick={closeAllMenu}>
            {t('navBar.Tradeview')}

            {/* Tradeview */}
          </NavLink>
        </NavMenu>
        <NavBtn>
          <div>
            <a
              className="login-form__link"
              style={{ marginLeft: '10px', width: '50px' }}
              onClick={() => updatelanguageZH()}>
              中文
            </a>
          </div>
          <div>
            <a
              className="login-form__link"
              style={{ marginRight: '10px' }}
              onClick={() => updatelanguageEN()}>
              English
            </a>
          </div>
          <NavItem>
            <IconButton
              onClick={() => {
                if (openMb) {
                  setOpenMb(false);
                } else {
                  setOpen(!open);
                  setOpen3(false);
                  setOpen2(false);
                }
              }}>
              <App />
            </IconButton>
            <IconButton
              onClick={() => {
                if (openMb) {
                  setOpenMb(false);
                } else {
                  setOpen2(!open2);
                  setOpen3(false);
                  setOpen(false);
                }
              }}>
              <Help />
            </IconButton>
            <IconButton
              onClick={() => {
                if (openMb) {
                  setOpenMb(false);
                } else {
                  setOpen3(!open3);
                  setOpen(false);
                  setOpen2(false);
                }
              }}>
              <Caret />
            </IconButton>
            {open ? <DropdownMenu /> : ''}
            {open2 ? <DropdownMenu2 /> : ''}
            {open3 ? <DropdownMenu3 {...props} /> : ''}
          </NavItem>

          {/* <NavBtnLink to="/settings">Settings</NavBtnLink> */}
        </NavBtn>

        {openMb ? (
          <DropdownMenuMb
            closeMenu={() => {
              open && setOpen(false);
              open2 && setOpen2(false);
              open3 && setOpen3(false);
              setOpenMb(false);
            }}
          />
        ) : (
          ''
        )}
      </Nav>
    </React.Fragment>
  );
};

const DropdownMenu = () => {
  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItem>
    );
  };
  return (
    <Dropdown>
      <DropdownItem leftIcon={<FaChartBar />} link="https://app.cryptosx.io">
        Crypto Exchange
      </DropdownItem>{' '}
      <DropdownItem
        leftIcon={<FaLandmark />}
        link="https://investorportal.cryptosx.io">
        STO Exchange
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaLayerGroup />}
        link="https://markets.cryptosx.io/dex">
        DEX
      </DropdownItem>
    </Dropdown>
  );
};

const DropdownMenu2 = () => {
  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link} target="_blank">
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItem>
    );
  };
  return (
    <Dropdown2>
      <DropdownItem
        leftIcon={<FaUserShield />}
        link="https://cryptosx.freshdesk.com/en/support/tickets/new">
        Support Center
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaSearch />}
        link="https://cryptosx.freshdesk.com/en/support/solutions">
        FAQs and Guides
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaMoneyBill />}
        link="https://cryptosx.freshdesk.com/en/support/solutions/articles/44001340353-unified-fee-schedule">
        Fee Schedule
      </DropdownItem>
    </Dropdown2>
  );
};

const DropdownMenu3 = p => {
  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link} onClick={props.onClick}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
      </DropdownMenuItem>
    );
  };
  const DropdownItemSpan = props => {
    return (
      <DropdownMenuItemSpan href={props.link} onClick={props.onClick}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
      </DropdownMenuItemSpan>
    );
  };
  // DropdownMenuItemSpan
  return (
    <Dropdown3>
      {// show current account
      p.selectedAccount && (
        <Link to="/settings/verification-level" style={{ width: '100%' }}>
          <DropdownItemSpan leftIcon={<FaUser />}>
            {p.selectedAccount.AccountName}
          </DropdownItemSpan>
        </Link>
      )}
      {p.selectableAccounts &&
        p.selectableAccounts.map(a => {
          return (
            <DropdownItem
              onClick={() => {
                p.selectAccount(a);
              }}
              key={a.AccountId}>
              Switch to {a.AccountName}
            </DropdownItem>
          );
        })}
      <DropdownItem
        leftIcon={<FaShareSquare />}
        onClick={async () => {
          p.logout();
        }}>
        Logout
      </DropdownItem>
    </Dropdown3>
  );
};

const Navbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(_Navbar);

const _DropdownMenuMb = props => {
  const DropdownItem = props => {
    return (
      <DropdownMenuItem href={props.link} onClick={props.onClick}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItem>
    );
  };

  const DropdownItemLink = props => {
    return (
      <DropdownMenuItemLink to={props.to} onClick={() => props.closeMenu()}>
        <DropdownMenuIcon>{props.leftIcon}</DropdownMenuIcon>
        {props.children}
        <DropdownMenuIconRight>{props.rightIcon}</DropdownMenuIconRight>
      </DropdownMenuItemLink>
    );
  };

  return (
    <DropdownMb>
      {// show current account
      props.selectedAccount && (
        <DropdownItemLink
          leftIcon={<FaUser />}
          to="/settings/verification-level"
          closeMenu={props.closeMenu}>
          {props.selectedAccount.AccountName}
        </DropdownItemLink>
      )}
      {props.selectableAccounts &&
        props.selectableAccounts.map(a => {
          return (
            <DropdownItem
              onClick={() => {
                props.selectAccount(a);
                props.closeMenu();
              }}
              key={a.AccountId}>
              Switch to {a.AccountName}
            </DropdownItem>
          );
        })}
      <DropdownItemLink
        leftIcon={<FaTable />}
        to="/digital-assets"
        closeMenu={props.closeMenu}>
        Digital Assets
      </DropdownItemLink>
      <DropdownItemLink
        leftIcon={<FaWallet />}
        to="/wallets"
        closeMenu={props.closeMenu}>
        Wallets
      </DropdownItemLink>
      {/* <DropdownItemLink
        leftIcon={<FaRandom />}
        to="/buy-sell"
        closeMenu={props.closeMenu}>
        Convert
      </DropdownItemLink> */}
      <DropdownItemLink
        leftIcon={<FaRegCreditCard />}
        to="/credit-card"
        closeMenu={props.closeMenu}>
        Credit Card
      </DropdownItemLink>
      <DropdownItemLink
        leftIcon={<FaUserCog />}
        to="/settings/verification-level"
        closeMenu={props.closeMenu}>
        Profile/KYC
      </DropdownItemLink>
      <DropdownItemLink
        leftIcon={<FaRegChartBar />}
        to="/exchange"
        closeMenu={props.closeMenu}>
        Tradeview
      </DropdownItemLink>
      <DropdownItem
        leftIcon={<FaShareSquare />}
        onClick={() => {
          props.logout();
        }}
        closeMenu={props.closeMenu}>
        Logout
      </DropdownItem>
      <h2
        style={{
          textAlign: 'center',
          padding: '10px 0',
          background: '#4283fc',
          borderRadius: '5px',
          color: '#fff'
        }}>
        Other Apps
      </h2>
      <DropdownItem leftIcon={<FaChartBar />} link="https://app.cryptosx.io">
        Crypto Exchange
      </DropdownItem>
      {/* <DropdownItem
        leftIcon={<FaLandmark />}
        link="https://investorportal.cryptosx.io">
        STO Exchange
      </DropdownItem> */}
      <DropdownItem
        leftIcon={<FaLayerGroup />}
        link="https://markets.cryptosx.io/dex">
        DEX
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaUserShield />}
        link="https://cryptosx.freshdesk.com/en/support/tickets/new">
        Support Center
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaSearch />}
        link="https://cryptosx.freshdesk.com/en/support/solutions">
        FAQs and Guides
      </DropdownItem>
      <DropdownItem
        leftIcon={<FaMoneyBill />}
        link="https://cryptosx.freshdesk.com/en/support/solutions/articles/44001340353-unified-fee-schedule">
        Fee Schedule
      </DropdownItem>
    </DropdownMb>
  );
};

const DropdownMenuMb = connect(
  mapStateToProps,
  mapDispatchToProps
)(_DropdownMenuMb);

export default Navbar;
// export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
