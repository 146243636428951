var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

import React, { createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createChart, CrosshairMode, isBusinessDay } from 'lightweight-charts';
import { connect } from 'react-redux';
import get from 'lodash/get';
import config from 'apex-web/lib/config';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { getCSSVar } from 'apex-web/lib/helpers/cssVarHelper';
import { IntervalButtons } from './IntervalButtons';
import { formatTime } from 'apex-web/lib/helpers/dateHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

var LightWeightCharts = config.LightWeightCharts,
  global = config.global;
var locale = global.locale;

var cssVar = getCSSVar();

var bullish = cssVar('--chart__bullish-color');
var bearish = cssVar('--chart__bearish-color');

function makeOHLC(frame) {
  return {
    time: frame[0] / 1000,
    high: frame[1],
    low: frame[2],
    open: frame[3],
    close: frame[4],
    volume: frame[5]
  };
}

function makeValueFrame(frame) {
  var targetIndex =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;

  return {
    time: frame[0] / 1000,
    value: frame[targetIndex]
  };
}

function getFrameFormatter(chartType) {
  if (chartType === 'candlestick') return makeOHLC;
  return function(frame) {
    return makeValueFrame(frame);
  };
}

function getChartConstructor(chartType) {
  if (chartType === 'candlestick') return 'addCandlestickSeries';
  return 'addAreaSeries';
}

function getChartConfig(chartType) {
  if (chartType === 'candlestick') {
    return {
      upColor: bullish,
      downColor: bearish,
      wickUpColor: bullish,
      wickDownColor: bearish,
      borderVisible: false
    };
  }
  return {
    bottomColor: cssVar('--default-price-line-color'),
    topColor: bullish,
    color: bullish,
    lineColor: bullish,
    priceLineColor: bullish,
    lineStyle: 0,
    lineWidth: 2
  };
}

export var LightWeightChart = (function(_React$Component) {
  _inherits(LightWeightChart, _React$Component);

  function LightWeightChart() {
    _classCallCheck(this, LightWeightChart);

    var _this = _possibleConstructorReturn(
      this,
      (
        LightWeightChart.__proto__ || Object.getPrototypeOf(LightWeightChart)
      ).call(this)
    );

    _this.resizeHandler = function() {
      _this.chart.applyOptions({
        width: _this.chartContainerRef.current.offsetWidth,
        height: _this.chartContainerRef.current.offsetHeight
      });
    };

    _this.chart = null;
    _this.chartContainerRef = createRef();
    _this.wrapperRef = createRef();
    _this.priceSeries = null;
    _this.volumeSeries = null;
    return _this;
  }

  _createClass(LightWeightChart, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        var _this2 = this;

        var selectedInstrument = this.props.selectedInstrument;

        var decimals = convertIncrementToIntDecimalPlaces(
          selectedInstrument.PriceIncrement
        );

        this.chart = createChart(this.chartContainerRef.current, {
          height: this.wrapperRef.current.offsetHeight,
          width: this.wrapperRef.current.offsetWidth,
          layout: {
            fontFamily: cssVar('--font__typeface'),
            // backgroundColor: cssVar("--secondary__bg-color"),
            backgroundColor: '#1d1e22',
            textColor: cssVar('--font__color-secondary')
          },
          grid: {
            vertLines: {
              // color: cssVar("--tv-chart__grid-vertical-color"),
              color: 'rgba(102, 95, 99, 0.5)'
            },
            horzLines: {
              // color: cssVar("--tv-chart__grid-horizontal-color"),
              color: 'rgba(102, 95, 99, 0.5)'
            }
          },
          crosshair: {
            mode: CrosshairMode.Normal
          },
          priceScale: {
            borderColor: cssVar('--tv-chart__scale-font-color')
          },
          timeScale: {
            borderColor: cssVar('--tv-chart__scale-font-color'),
            rightBarStaysOnScroll: true,
            timeVisible: true
          },
          localization: {
            locale: locale,
            dateFormat: LightWeightCharts.dateFormat,
            timeFormatter: function timeFormatter(businessDayOrTimestamp) {
              if (isBusinessDay(businessDayOrTimestamp)) {
                return '';
              }
              return formatTime(businessDayOrTimestamp * 1000);
            },
            priceFormatter: function priceFormatter(price) {
              return formatNumberToLocale(price, decimals);
            }
          }
        });

        window.addEventListener('resize', this.resizeHandler);

        var func = getChartConstructor(
          this.props.chartType || config.LightWeightCharts.chartType
        );
        var chartConfig = getChartConfig(
          this.props.chartType || config.LightWeightCharts.chartType
        );

        this.priceSeries = this.chart[func](chartConfig);

        if (!this.props.hideVolume && config.LightWeightCharts.showVolume) {
          this.volumeSeries = this.chart.addHistogramSeries({
            base: 0,
            color: 'rgba(238, 238, 238, 0.2)',
            priceFormat: {
              type: 'volume'
            },
            overlay: true,
            scaleMargins: {
              top: 0.8,
              bottom: 0
            }
          });
        }

        var priceData = [];
        var volData = [];
        this.props.priceData.forEach(function(el) {
          priceData.push(
            getFrameFormatter(
              _this2.props.chartType || config.LightWeightCharts.chartType
            )(el)
          );
          volData.push(makeValueFrame(el, 5));
        });
        this.priceSeries.setData(priceData);
        this.volumeSeries && this.volumeSeries.setData(volData);
      }
    },
    {
      key: 'componentDidUpdate',
      value: function componentDidUpdate(prevProps) {
        var _this3 = this;

        if (prevProps.selectedInstrument !== this.props.selectedInstrument) {
          var decimalPlaces = convertIncrementToIntDecimalPlaces(
            this.props.selectedInstrument.PriceIncrement
          );
          this.chart.applyOptions({
            localization: {
              priceFormatter: function priceFormatter(price) {
                return formatNumberToLocale(price, decimalPlaces);
              }
            }
          });
        }

        if (
          prevProps.selectedInstrumentId !== this.props.selectedInstrumentId
        ) {
          this.priceSeries.setData([]);
          this.volumeSeries && this.volumeSeries.setData([]);
        }

        if (prevProps.priceData !== this.props.priceData) {
          var priceData = [];
          var volData = [];

          this.props.priceData.forEach(function(el) {
            priceData.push(
              getFrameFormatter(
                _this3.props.chartType || config.LightWeightCharts.chartType
              )(el)
            );
            volData.push(makeValueFrame(el, 5));
          });

          this.priceSeries.setData(priceData);
          this.volumeSeries && this.volumeSeries.setData(volData);
        }
      }
    },
    {
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
        this.chart.remove();
      }
    },
    {
      key: 'render',
      value: function render() {
        return React.createElement(
          'div',
          { id: 'lightweight-chart', ref: this.wrapperRef },
          React.createElement('div', {
            id: 'lightweight-chart-container',
            className: 'lightweight-chart',
            ref: this.chartContainerRef
          }),
          !this.props.hideIntervalButtons &&
            React.createElement(IntervalButtons, null)
        );
      }
    }
  ]);

  return LightWeightChart;
})(React.Component);

var mapStateToProps = function mapStateToProps(state) {
  var selectedInstrument = selectedInstrumentSelector(state);
  return {
    selectedInstrument: selectedInstrument,
    selectedInstrumentId: selectedInstrument.InstrumentId,
    priceData: get(state, 'tickers.tickers', []),
    selectedInterval: state.tickers.interval
  };
};

LightWeightChart.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(LightWeightChart);
