import { connect } from 'react-redux';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import UpdateConfigFormComponent from './UpdateConfigFormComponent';
import {
  signup,
  clearSignupError
} from 'apex-web/lib/redux/actions/signupActions';
import { preprocessErrorTextForReduxForm } from 'apex-web/lib/helpers/errorTextFormatter';
import get from 'lodash/get';
import { getUserConfig } from 'apex-web/lib/redux/actions/userActions';

var UpdateConfigFormForm = reduxForm({
  form: 'signup',
  onSubmit: function onSubmit(payload, dispatch) {
    alert(JSON.stringify(payload));
    /*return dispatch(signup(payload)).then(function(response) {
      if (response && response.errormsg) {
        if (response.errormsg === 'Username already exists.') {
          response.errormsg = 'Email already exists.';
        }
        throw new SubmissionError(
          preprocessErrorTextForReduxForm(response.errormsg, payload)
        );
      }
    });*/
  }
})(props => {
  return <UpdateConfigFormComponent {...props} />;
});

var selector = formValueSelector('signup');

var mapStateToProps = function mapStateToProps(state, ownProps) {
  return Object.assign(
    {
      passwordValue: selector(state, 'password'),
      errorMsg: get(state, 'form.signup.error', false),
      done: !!state.signup.userId,
      userConfigs: state.user.userConfig
    },
    ownProps
  );
};

var mapDispatchToProps = dispatch => ({
  getUserCnnfig: (UserId, UserName) => dispatch(getUserConfig(UserId, UserName))
});

var UpdateConfigFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateConfigFormForm);

export default UpdateConfigFormContainer;
