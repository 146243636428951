import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changeTickersInterval as _changeTickersInterval } from 'apex-web/lib/redux/actions/tickersActions';
import {
  SUPPORTED_TICKER_INTERVALS,
  convertLabelToTickerInterval
} from 'apex-web/lib/helpers/tickerHelper';
import config from 'apex-web/lib/config';
import './IntervalButtons.css';

export var IntervalButtons = function IntervalButtons(props, context) {
  return React.createElement(
    'div',
    { className: 'interval-buttons-wrapper' },
    config.LightWeightCharts.supportedIntervals.map(function(i) {
      return React.createElement(ConnectedIntervalButton, {
        key: i,
        interval: i,
        value: SUPPORTED_TICKER_INTERVALS[i]
      });
    })
  );
};

IntervalButtons.contextTypes = {
  t: PropTypes.func.isRequired
};

var IntervalButton = function IntervalButton(props, context) {
  return React.createElement(
    'button',
    {
      className:
        'ap-inline-btn__btn lightweight-interval-button ' +
        (props.selectedInterval === props.value ? 'active' : ''),
      label: props.interval,
      onClick: props.changeTickersInterval,
      type: 'button'
    },
    React.createElement('span', null, context.t(props.interval))
  );
};

IntervalButton.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    selectedInterval: state.tickers.interval
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    changeTickersInterval: function changeTickersInterval() {
      dispatch(
        _changeTickersInterval(convertLabelToTickerInterval(ownProps.interval))
      );
    }
  };
};
var ConnectedIntervalButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntervalButton);
