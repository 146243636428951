import React from 'react';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import Mobilum from '../components/Mobilum/index.nightly';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import {
  JoomioContainer as JoomioContainer_,
  BuySellMakeTransactionContainer
} from '../components/Joomio';
import 'apex-web/lib/layouts/Responsive/EotcBuySellLayout/EotcBuySellDesktopLayout.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import TradingViewWidget, {
  Themes,
  RangeTypes
} from 'react-tradingview-widget';
import CardImg from '../images/custom/cards.png';
import './JoomioPage.css';
import { useTranslation } from 'react-i18next';

let JoomioContainer = JoomioContainer_();

const classes = getBEMClasses('eotc-buy-sell-layout');

const BuySellPage = (props, cont) => {
  const [t, i18n] = useTranslation('common');
  const [widget, setWidget] = React.useState();
  // console.log('context',context.t(''))
  return (
    <React.Fragment>
      {!!widget ? (
        <div>
          <div className={classes('accent-bar')} />
          <div className={classes('container')}>
            <div className={classes('col', ['lg'])}>
              <div className="title-container">
                <div className="col-left">
                  <h2>Card Purchase - Enter your card details</h2>
                </div>
                <div className="col-right">
                  <img className="card-img" src={CardImg} />
                </div>
              </div>
              <div className="cp-container">
                <Mobilum {...widget} />
              </div>
            </div>
          </div>
          <div className={classes('footer')}>
            <PageFooterComponent />
          </div>
        </div>
      ) : (
        <div>
          <div className={classes('accent-bar')} />
          <div className={classes('container')}>
            <div className={classes('col', ['lg'])}>
              <div className="title-container">
                <div className="col-left">
                  <h2 />
                </div>
                <div className="col-right">
                  <img className="card-img" src={CardImg} />
                </div>
              </div>
              <div
                className={classes(
                  'make-transaction-container container-with-shadow'
                )}>
                <BuySellMakeTransactionContainer>
                  <JoomioContainer
                    redirectToMobilum={setWidget}
                    prices={[100, 250, 500]}
                  />
                </BuySellMakeTransactionContainer>
              </div>
            </div>
          </div>
          <div className={classes('footer')}>
            <PageFooterComponent />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withAuthentication(BuySellPage);
