import React from 'react';
import PropTypes from 'prop-types';
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var FiatWithdrawDetailsComponent = (function(_React$Component) {
  _inherits(FiatWithdrawDetailsComponent, _React$Component);

  function FiatWithdrawDetailsComponent() {
    _classCallCheck(this, FiatWithdrawDetailsComponent);

    return _possibleConstructorReturn(
      this,
      (
        FiatWithdrawDetailsComponent.__proto__ ||
        Object.getPrototypeOf(FiatWithdrawDetailsComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(FiatWithdrawDetailsComponent, [
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var _props = this.props,
          Amount = _props.data.Amount,
          ProductSymbol = _props.product.ProductSymbol,
          balance = _props.balance,
          fee = _props.fee,
          detailsInfo = _props.detailsInfo,
          TicketAmount = _props.TicketAmount;
        var getItem = SidePaneDetailsComponent.getItem;

        var currentBalance = balance
          ? formatNumberToLocale(balance, ProductSymbol) + ' ' + ProductSymbol
          : '-';
        var amount = TicketAmount
          ? formatNumberToLocale(TicketAmount, ProductSymbol) +
            ' ' +
            ProductSymbol
          : '-';

        /**
         * TicketAmount = `GetWithdrawFee`.TicketAmount.
         *  If (Gateway.`DeductWithdrawFee` = true), fee is already deducted.
         */

        const customDetials = productSymbol => {
          //console.log('aaa')
          //console.log(productSymbol);
          if (productSymbol === 'HCS') {
            return React.createElement(
              'div',
              { className: 'HCSdetailsContainer' },
              React.createElement(
                'h3',
                { className: 'HCSdetailsHeader' },
                'HCS tokens are subject to the following transfer restrictions:'
              ),
              React.createElement(
                'p',
                { className: 'HCSdetails' },
                'The intended recipient and its digital wallet must have previously gone through a “Know-Your-Customer” process with the Company and/or its appointed administrator. Otherwise, unregistered wallets will not be able to send or receive from any addres'
              ),
              React.createElement(
                'p',
                { className: 'HCSdetails' },
                "No HCS Whisky Token can be transferred to the residents of Afghanistan (AF), Cuba (CU), Iraq (IQ), Iran, Islamic Republic of (IR), Korea, Democratic People's Republic of (KP), South Sudan (SS), Sudan (SD), Syrian Arab Republic (SY), Ukraine (UA), Guam (GU), Northern Mariana Islands (MP), American Samoa (AS), Puerto Rico (PR), Virgin Islands, U.S. (VI), United States (US)."
              ),
              React.createElement(
                'p',
                { className: 'HCSdetails' },
                'Investors in the United States and its related territories cannot hold HCS Whisky Tokens'
              ),
              React.createElement(
                'p',
                { className: 'HCSdetails' },
                'Holders must hold a minimum of 10 HCS Whisky Tokens. Trades that would result in either party having greater than zero and less than 10 HCS Whisky Tokens will fail.'
              ),
              React.createElement(
                'p',
                { className: 'HCSdetails' },
                'A maximum of 150 EU retail investors per country will have tokens. This is aimed to comply with A] “qualified investors exemption, a.k.a. private placement” in Europe, which allows selling security tokens freely to qualified investors, meaning mainly institutional ones, high net worth individuals and their likes. Assumption that there’s a proper private placement memorandum and strong subscription agreement for the investors. B] “limited network exemption” limiting selling the security token to up to 150 people. Those exemptions mean issuer doesn’t have to draft a prospectus. ○ If an EU retail investor is to receive tokens and had a 0 balance before, the trade will only be possible if the total number of retail investors in their country is less than 150 or the sender is sending all their tokens.'
              )
            );
            // React.createElement(
            //   'p',
            //   { className: "HCSwithdrawTerms" },
            //   'This is testing for other FIAT currencies'
            // )
          } else {
            return React.createElement(
              'p',
              { className: 'HCSwithdrawTerms' },
              // 'This is testing for other FIAT currencies'
              "Note - The withdrawal and transfer of these funds might take up to 7 business days, and it's subject to operator review."
            );
          }
        };
        let formatWithdrawFee = fee =>
          fee
            ? formatNumberToLocale(fee, ProductSymbol) +
              ' ' +
              ({ HCS: 'USDT ' }[ProductSymbol] || ProductSymbol)
            : '-';

        var remainingBalance =
          balance && Amount
            ? formatNumberToLocale(
                balance -
                  (Amount === TicketAmount ? TicketAmount : TicketAmount + fee),
                ProductSymbol
              ) +
              ' ' +
              ProductSymbol
            : '-';
        var items = [
          getItem(
            this.context.t('Your current {ProductSymbol} Balance', {
              ProductSymbol: ProductSymbol
            }),
            currentBalance
          ),
          getItem(this.context.t('Amount to Withdraw'), amount),
          {
            label: this.context.t('Fee'),
            text: formatWithdrawFee(fee)
          },
          getItem(this.context.t('Remaining Balance'), remainingBalance)
        ];

        var info = detailsInfo.map(function(item) {
          if (item.useLink) {
            return React.createElement(
              'a',
              { href: item.linkAddress },
              _this2.context.t('{text}', { text: item.text })
            );
          } else {
            return customDetials(ProductSymbol);
            //           return _this2.context.t('{text}', { text: item.text });
          }
        });

        return React.createElement(SidePaneDetailsComponent, {
          title: this.context.t('Balances'),
          items: items,
          info: info,
          classModifiers: 'withdraw'
        });
      }
    }
  ]);

  return FiatWithdrawDetailsComponent;
})(React.Component);

FiatWithdrawDetailsComponent.propTypes = {
  FullName: PropTypes.string,
  Amount: PropTypes.number,
  BankAccountName: PropTypes.string,
  BankAccountNumber: PropTypes.string,
  BankAddress: PropTypes.string,
  Comment: PropTypes.string
};

FiatWithdrawDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatWithdrawDetailsComponent;
