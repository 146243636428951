import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APIconButton from 'apex-web/lib/components/common/APIconButton/APIconButton';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import APIFrame from 'apex-web/lib/components/common/APIFrame/APIFrame';
import { useDigitalAssets } from './DigitalAssetsHooks';

import './DigitalAssetsOfferingProfilePage.css';
import PropTypes from 'prop-types';

const classes = getBEMClasses('offering-profile');

const noProfileFoundURL =
  'https://offering-profiles-samples.surge.sh/notfound.html';

const DigitalAssetsOfferingProfilePage = (
  {
    location: { pathname },
    isWithdrawDisabled,
    openSendReceiveSidePane,
    instruments,
    selectInstrument,
    selectedInstrument,
    unsubscribeLevel2,
    price
  },
  context
) => {
  const { digitalAssets, digitalAssetsTotals } = useDigitalAssets(
    instruments,
    selectInstrument,
    selectedInstrument,
    unsubscribeLevel2
  );
  useScrollToTop();
  const path = pathname.replace(/\/$/, '');
  const productId = path.split('/').pop();
  const product = digitalAssets.data.find(
    p => p.ProductId === parseInt(productId, 10)
  );

  const { total } = digitalAssets.data.length
    ? digitalAssetsTotals.find(
        total =>
          total.Product1 === product.ProductId ||
          total.Product2 === product.ProductId
      ) || { total: 0 }
    : { total: 0 };

  return (
    <React.Fragment>
      {product && (
        <React.Fragment>
          <div className={classes('header')}>
            <div className={classes('left')}>
              <span className={classes('symbol')}>{product.Product}</span>
              &nbsp;&nbsp;&nbsp;
              <span className={classes('product')}>
                {product.ProductFullName}
              </span>
            </div>
            <div className={classes('right')}>
              <APIconButton
                iconName={'deposit'}
                action={() => {
                  openSendReceiveSidePane(product);
                }}
                customClass={classes('padding')}
                label={context.t('   Buy')}
              />
              &nbsp;&nbsp;&nbsp;
              <APIconButton
                style={{ border: '1px solid red' }}
                iconName={'withdraw'}
                action={() => {
                  openSendReceiveSidePane(product, '1');
                }}
                customClass={classes('padding')}
                isDisabled={isWithdrawDisabled}
                label={context.t('   Sell')}
              />
            </div>
          </div>
          <div className={classes('header')}>
            <div className={classes('left')}>
              <span className={classes('tabs')}>
                {context.t('Asset Details')}
              </span>
            </div>
            <div className={classes('right')}>
              {context.t('Price:')} ${price}
              {/*
              {context.t('Number of tokens for sale:')} {total}
            */}
            </div>
          </div>
          <div className={classes('container')}>
            <div className={classes('iframe-container')}>
              <APIFrame
                src={product.OfferingProfileURL || noProfileFoundURL}
                title={product.Product}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

DigitalAssetsOfferingProfilePage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default withAuthentication(withRouter(DigitalAssetsOfferingProfilePage));

const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};
