import React from 'react';
import PropTypes from 'prop-types';
import { templateFormRenderer } from 'apex-web/lib/helpers/formTemplateHelper';
import { required, biggerThanZero } from 'apex-web/lib/helpers/formValidations';

import WithdrawWorkflowsContainer from '../WithdrawWorkflows/WithdrawWorkflowsContainer';
import APButton from 'apex-web/lib/components/common/APButton';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var WithdrawTemplateFormComponent = (function(_React$Component) {
  _inherits(WithdrawTemplateFormComponent, _React$Component);

  function WithdrawTemplateFormComponent() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, WithdrawTemplateFormComponent);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          WithdrawTemplateFormComponent.__proto__ ||
          Object.getPrototypeOf(WithdrawTemplateFormComponent)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.onMaxClick = function() {
        var _this$props = _this.props,
          onAmountChange = _this$props.onAmountChange,
          change = _this$props.change,
          balance = _this$props.balance;

        change('createWithdraw', 'Amount', balance);
        onAmountChange(balance);
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(WithdrawTemplateFormComponent, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          product = _props.product,
          _props$product = _props.product,
          Product = _props$product.Product,
          ProductId = _props$product.ProductId,
          DecimalPlaces = _props$product.DecimalPlaces,
          withdrawWorkflowInfo = _props.withdrawWorkflowInfo,
          submitting = _props.submitting,
          invalid = _props.invalid,
          baseClasses = _props.baseClasses,
          onAmountChange = _props.onAmountChange,
          children = _props.children,
          errorMessage = _props.errorMessage,
          disableWithdraw = _props.disableWithdraw,
          VerificationRequiredComponent = _props.VerificationRequiredComponent;

        var useExternalWorkflow =
          withdrawWorkflowInfo && Object.values(withdrawWorkflowInfo).length;

        var template = Object.assign({}, this.props.template);

        if ('ExternalAddress' in template) {
          // Has to be a new object adding/modifying a prop causes infinite renders AW-515
          template = Object.assign(
            { ExternalAddress: Product },
            this.props.template
          );
        }

        return Object.keys(template).length && ProductId
          ? React.createElement(
              React.Fragment,
              null,
              React.createElement(
                'div',
                { className: baseClasses('wrapper') },
                !template.hasOwnProperty('Amount') &&
                  React.createElement(
                    'div',
                    { className: baseClasses('amount-with-btn') },
                    React.createElement(APNumberInput, {
                      type: 'text',
                      key: 'Amount',
                      name: 'Amount',
                      'data-test': 'Amount',
                      label: this.context.t('Amount'),
                      customClass: baseClasses(),
                      decimalPlaces: DecimalPlaces,
                      customChange: function customChange(value) {
                        return onAmountChange(+value);
                      },
                      validate: [required, biggerThanZero]
                    }),
                    React.createElement(
                      APButton,
                      {
                        customClass: baseClasses('max'),
                        type: 'button',
                        styleName: 'additive',
                        onClick: this.onMaxClick
                      },
                      this.context.t('Max')
                    )
                  ),
                errorMessage &&
                  React.createElement(
                    'div',
                    { className: baseClasses('error-message') },
                    errorMessage
                  ),
                !useExternalWorkflow &&
                  templateFormRenderer(
                    template,
                    baseClasses(''),
                    this.context,
                    product
                  )
              ),
              React.createElement(WithdrawWorkflowsContainer, null),
              !useExternalWorkflow &&
                React.createElement(
                  React.Fragment,
                  null,
                  children,
                  React.createElement(
                    'div',
                    { className: baseClasses('section') },
                    React.createElement(
                      APButton,
                      {
                        customClass: baseClasses(),
                        type: 'submit',
                        disabled: submitting || invalid || disableWithdraw,
                        styleName: 'subtractive'
                      },
                      submitting
                        ? this.context.t('Submitting...')
                        : this.context.t('Place Withdraw Ticket')
                    ),
                    React.createElement(
                      'div',
                      { className: baseClasses('verification-required-text') },
                      VerificationRequiredComponent
                    )
                  )
                )
            )
          : React.createElement('div', null);
      }
    }
  ]);

  return WithdrawTemplateFormComponent;
})(React.Component);

WithdrawTemplateFormComponent.defaultProps = {
  template: {},
  product: {},
  submitting: false,
  baseClasses: function baseClasses() {},
  onAmountChange: function onAmountChange() {},
  VerificationRequiredComponent: null
};

WithdrawTemplateFormComponent.propTypes = {
  template: PropTypes.object,
  product: PropTypes.shape({
    Product: PropTypes.string,
    ProductId: PropTypes.number,
    DecimalPlaces: PropTypes.number
  }),
  submitting: PropTypes.bool,
  baseClasses: PropTypes.func,
  onAmountChange: PropTypes.func,
  VerificationRequiredComponent: PropTypes.element
};

WithdrawTemplateFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default WithdrawTemplateFormComponent;
