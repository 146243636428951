import { connect } from 'react-redux';
import { openRetailFiatSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import FiatNavItemsComponent from './FiatNavItemsComponent';

var mapDispatchToProps = {
  openRetailFiatSidePane: openRetailFiatSidePane
};

export default connect(
  null,
  mapDispatchToProps
)(FiatNavItemsComponent);
