import React from 'react';
import { Route } from 'react-router-dom';
import PageHeaderLayout from '../layouts/PageHeaderLayout/PageHeaderLayout';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';

import DashboardPage from './DashboardPage';
import MarketDataPage from './MarketDataPage';
import BuySellPage from './BuySellPage';
import WalletsPage from './WalletsPage';
import ProductDetailsPage from './ProductDetailsPage';
import ComponentExhibitPage from './ComponentExhibitPage/ComponentExhibitPage';
import SettingsPage from './SettingsPage';

import ExchangePage from '../pages/Exchange/ExchangePage';
import TradingLayout from 'apex-web/lib/layouts/TradingLayout/TradingLayoutContainer';
import MarginBalancesLayout from 'apex-web/lib/layouts/MarginBalancesLayout/MarginBalancesLayoutContainer';
import AssetActivityDetailsLayout from 'apex-web/lib/layouts/AssetActivityDetailsLayout/AssetActivityDetailsLayout';

import FixedLeftFluidLayout from 'apex-web/lib/layouts/FixedLeftFluidLayout/FixedLeftFluidLayout';
import WalletDetailsBackButtonComponent from 'apex-web/lib/components/WalletDetails/WalletDetailsBackButtonComponent';
import BalancesMenuContainer from 'apex-web/lib/components/BalancesMenu/BalanceMenuContainer';
import WalletDetailsLayout from 'apex-web/lib/layouts/WalletDetailsLayout/WalletDetailsLayout';

// import DigitalAssetsPage from 'apex-web/lib/components/DigitalAssets/DigitalAssetsPageContainer';
// import DigitalAssetsOfferingProfilePage from 'apex-web/lib/components/DigitalAssets/DigitalAssetsOfferingProfilePageContainer';
import DigitalAssetsPage from './DigitalAssets/DigitalAssetsPageContainer';
import DigitalAssetsOfferingProfilePage from './DigitalAssets/DigitalAssetsOfferingProfilePageContainer';

import JoomioPage from './JoomioPage';
import SuccessPage from './SuccessPage';
import ErrorPage from './ErrorPage';
import UpdateConfigPage from './UpdateConfigPage';
import IBALayout from 'apex-web/lib/components/IBA/IbaLayout';
import CashOutPage from './CashOutPage';
import PrepaidCardsPage from './PrepaidCardsPage';
import Navbar from '../components/NewNav';
// import NewNav from '../components/NewNav/NewNav';

const InteriorPage = () => (
  <React.Fragment>
    <Navbar />
    {/* <NewNav />
    <PageHeaderLayout />*/}
    <Route exact path={'/dashboard'} component={DashboardPage} />
    <Route exact path={'/dashboard/zh'} component={DashboardPage} />
    <Route exact path={'/dashboard/en'} component={DashboardPage} />

    <Route path={'/dashboard/market-data'} component={MarketDataPage} />
    {/* <Route exact path={'/iba'} component={IBALayout} /> */}
    <Route exact path={'/wallets'} component={WalletsPage} />
    <Route
      path="/wallets/wallet-detail"
      component={() => (
        <FixedLeftFluidLayout
          left={[
            <WalletDetailsBackButtonComponent key="1" />,
            <BalancesMenuContainer key="2" />
          ]}>
          <WalletDetailsLayout />
        </FixedLeftFluidLayout>
      )}
    />
    <Route path={'/exchange'} component={ExchangePage} />
    <Route path={'/buy-sell'} component={BuySellPage} />
    <Route path={'/wallets/product-details'} component={ProductDetailsPage} />
    <Route path={'/settings'} component={SettingsPage} />
    {/* <Route path={'/component'} component={ComponentExhibitPage} /> */}
    <Route
      path={'/margin-balances'}
      component={() => (
        <MarginBalancesLayout detailsLink="/wallets/wallet-detail" />
      )}
    />
    <Route
      path={'/asset-activity-details'}
      component={AssetActivityDetailsLayout}
    />

    {/* Investor portal Routes */}
    <Route exact path={'/digital-assets'} component={DigitalAssetsPage} />
    <Route
      path={'/digital-assets/:id'}
      component={DigitalAssetsOfferingProfilePage}
    />
    <Route exact path={'/credit-card'} component={JoomioPage} />
    <Route path={'/credit-card/success'} component={SuccessPage} />
    <Route path={'/credit-card/error'} component={ErrorPage} />

    {/**<Route path={'/update-config'} component={UpdateConfigPage} /> */}
    {/*
    <Route exact path={'/cash-out'} component={CashOutPage} />
    <Route path={'/cash-out/prepaid-cards'} component={PrepaidCardsPage} />
    <Route path={'/cash-out/gift-cards'} component={GiftCardsPage} />*/}
  </React.Fragment>
);

export default withAuthentication(InteriorPage);
