import React from 'react';
import { connect } from 'react-redux';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './index.css';
import Email from './email.png';

let EmailPromptModalClasses = getBEMClasses('fiat-withdraw-modal');

let EmailPromptModal = props => {
  return React.createElement(
    Modal,
    {
      isOpen: props.open,
      title: React.createElement(
        'div',
        { className: EmailPromptModalClasses('header-content') },
        React.createElement('img', {
          src: Email,
          alt: 'Email'
        }),
        React.createElement(
          'div',
          { className: EmailPromptModalClasses('header-title') },
          'Check your mail inbox'
        )
      ),
      close: props.close,
      footer: {
        buttonText: 'Cancel',
        buttonStyle: 'additive',
        onClick: () => {}
      },
      customClass: EmailPromptModalClasses()
    },
    React.createElement(
      'div',
      { className: EmailPromptModalClasses('title') },
      'Please check your email inbox or the “SPAM” folder for 2-FA confirmation, your withdraw will be processed after we have received your confirmation via email.'
    )
  );
};

let EmailPromptModalContainer = props => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(
    () => {
      if (
        (props.snacks || []).filter(e => e['id'] === 'withdrawSuccessSnack')
          .length != 0
      ) {
        setOpen(true);
      }
    },
    [props.snacks]
  );
  return React.createElement(EmailPromptModal, {
    ...props,
    open,
    close: () => setOpen(false)
  });
};

let mapStateToProps = state => ({
  snacks: state.snackbar.snacks
});

export default connect(mapStateToProps)(EmailPromptModalContainer);
