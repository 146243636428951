import React, { Component, useEffect } from 'react';
import L from 'lodash';
import './InstrumentTableComponent.css';

const InstrumentTickers = props => {
  // console.log(props.positions);

  // this code is to change Amount & AvailableBalance value to only 2 decimals place. raw style code for this huh.
  // note that this ite (investor portal) has different logic then app.cryptosx.io , ok ?
  if (
    props.positions.position2.Amount &&
    props.positions.position2.AvailableBalance
  ) {
    var position2_Amount = props.positions.position2.Amount;

    var dot_position_Amount = position2_Amount.indexOf(['.']);

    var comma_position_Amount = position2_Amount.indexOf([',']);

    if (comma_position_Amount > dot_position_Amount) {
      position2_Amount = props.positions.position2.Amount;
    } else {
      position2_Amount = position2_Amount.slice(0, dot_position_Amount + 3);
    }

    var position2_AvailableBalance = props.positions.position2.AvailableBalance;

    var dot_position_AvailableBalance = position2_AvailableBalance.indexOf('.');

    var comma_position_AvailableBalance = position2_AvailableBalance.indexOf(
      ','
    );

    if (comma_position_AvailableBalance > dot_position_AvailableBalance) {
      position2_AvailableBalance = props.positions.position2.AvailableBalance;
    } else {
      position2_AvailableBalance = position2_AvailableBalance.slice(
        0,
        dot_position_AvailableBalance + 3
      );
    }
  }

  return (
    <div className="instrument-table">
      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">
          {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
        </span>
        <span className="instrument-table__value instrument-table__value--last-price">
          {/* this price is changed, requested by Philip.
          For price come to 0 fall-back.
          If the price is 0 , use another from props. 
          Feel free to add more if-else case (LMAO)
          */}
          {L.get(props.level1, ['LastTradedPx']) == 0
            ? L.get(props.level1, ['SessionClose']) == 0
              ? L.get(props.level1, ['BestOffer'])
              : L.get(props.level1, ['LastTradedPx'])
            : L.get(props.level1, ['SessionClose'])}
        </span>
      </div>
      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">Your Balance:</span>
        <span className="instrument-table__value">
          <span className="instrument-table__title">
            {L.get(props.positions, ['position1', 'ProductSymbol'])}{' '}
          </span>
          {L.get(props.positions, ['position1', 'Amount'])}{' '}
          <span className="instrument-table__title">
            {' '}
            / {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
          </span>
          {/* change the number to 2 decimals , if only "-" , fallback to default  */}
          {/* will also check if it is USDT or USD */}
          {(L.get(props.positions, ['position2', 'Amount']) != '-' &&
            L.get(props.positions, ['position2', 'ProductSymbol']) == 'USDT') ||
          L.get(props.positions, ['position2', 'ProductSymbol']) == 'USD'
            ? position2_Amount
            : L.get(props.positions, ['position2', 'Amount'])}
        </span>
      </div>
      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">Avaliability:</span>
        <span className="instrument-table__value">
          <span className="instrument-table__title">
            {L.get(props.positions, ['position1', 'ProductSymbol'])}{' '}
          </span>
          {L.get(props.positions, ['position1', 'AvailableBalance'])}{' '}
          <span className="instrument-table__title">
            {' '}
            / {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
          </span>
          {/* change the number to a tofixed(2) , if only "-" , fallback to default  */}
          {/* will also check if it is USDT or USD */}
          {(L.get(props.positions, ['position2', 'AvailableBalance']) != '-' &&
            L.get(props.positions, ['position2', 'ProductSymbol']) == 'USDT') ||
          L.get(props.positions, ['position2', 'ProductSymbol']) == 'USD'
            ? position2_AvailableBalance
            : L.get(props.positions, ['position2', 'AvailableBalance'])}
        </span>
      </div>
    </div>
  );
};
export default InstrumentTickers;
