import { useEffect, useState } from 'react';
import apex from 'apex-web/lib/apex';
import { modifyDigitalAsset } from './hijack';

export const useDigitalAssets = (
  instruments,
  selectInstrument,
  selectedInstrument,
  unsubscribeLevel2
) => {
  const [digitalAssets, setDigitalAssets] = useState({
    data: [],
    fetching: true
  });
  const [price, setPrice] = useState(0);
  const [digitalAssetsTotals, setDigitalAssetTotals] = useState([]);
  useEffect(() => {
    (async () => {
      await getProductsAndAddAttributes()
        .then(response => {
          setDigitalAssets({
            data: response.filter(x => x.Type === 'DigitalAsset'),
            fetching: false
          });
        })
        .catch(err => {
          setDigitalAssets({ data: [], fetching: false });
        });
    })();
  }, []);

  useEffect(
    () => {
      if (digitalAssets.data.length) {
        unsubscribeLevel2(selectedInstrument);
        const matchingInstruments = digitalAssets.data
          .map(product => {
            return instruments.filter(
              i =>
                i.Product1 === product.ProductId ||
                i.Product2 === product.ProductId
            );
          })
          .flat();
        Promise.all(
          matchingInstruments.map(async mi => {
            const level2 = await apex.connection.SubscribeLevel2({
              OMSId: 1,
              InstrumentId: mi.InstrumentId,
              Depth: 250
            });
            const [priceLevel] = level2;
            const incomingPrice = priceLevel ? priceLevel[6] : 0;
            if (incomingPrice) {
              setPrice(incomingPrice);
            } else {
              setPrice(0);
            }
            const total =
              level2 &&
              level2
                .filter(y => y[9] === 1)
                .reduce((acc, curr) => acc + curr[8], 0);
            await apex.connection
              .UnsubscribeLevel2({ OMSId: 1, InstrumentId: mi.InstrumentId })
              .catch(err => console.error(err));
            return {
              Product1: mi.Product1,
              Product2: mi.Product2,
              total
            };
          })
        ).then(response => setDigitalAssetTotals(response));

        selectInstrument(selectedInstrument);
      }
    },
    [digitalAssets]
  );

  return { digitalAssets, digitalAssetsTotals, price };
};

export const useSendOrder = order => {
  useEffect(() => {
    (async () => {
      await apex.connection.SendOrder(order);
    })();
  }, []);
};

const getProductsAndAddAttributes = async () => {
  const products = await apex.connection.GetProducts();
  // console.log()
  // const newData = await [...products].map((product,i)=> ({...product}.Upcoming))
  // await console.log(newData)
  const data = await Promise.all(
    products.map(async product => {
      const attributes = await apex.connection.GetProductAttributes({
        ProductId: product.ProductId,
        OMSId: 1
      });
      attributes.forEach(
        ({ KeyName, KeyValue }) => (product[KeyName] = KeyValue)
      );
      return product;
    })
  );
  const rearrangeData = await [...data];
  const rearrangeData2 = rearrangeData.map((item, i) => item.Product);
  const sequence2 = [
    'BTC',
    'ETH',
    'LTC',
    'BCH',
    'FB',
    'USD',
    'EUR',
    'XRP',
    'HCS',
    'CCT',
    'AGWD',
    'USDT',
    'ABC',
    'TRO',
    'FST',
    'LQGO',
    'TBD',
    'MAMI',
    '2UT',
    'RVN',

    'GEST',
    'INDIGO',
    'DPGV',
    'AMAGOLD',
    'YECO',
    'GODL',
    'AGRI',
    'GINV',
    'DAGO',
    'IPGS',
    'DIGau',
    'ATMO',
    'DGI',
    'LOTS',
    'TIRC',
    'DORE',
    'MSTO',
    'AOC',
    'U2U',
    'GNBT'
  ];

  rearrangeData.forEach((item, i) => {
    if (sequence2.includes(item.Product)) {
      sequence2[sequence2.indexOf(item.Product)] = modifyDigitalAsset(item);
    }
  });

  return sequence2;
};
