import React from 'react';
import PropTypes from 'prop-types';
import TrustpayWithdrawWorkflowContainer from '../PaymentProcessors/TrustpayWithdrawWorkflowContainer';
import HyphenWithdrawWorkflowContainer from '../PaymentProcessors/HyphenWithdrawWorkflowContainer';
import EOTCWithdrawWorkflowContainer from 'apex-web/lib/components/Retail/RetailSidePanes/FiatWithdraw/BankSelect/BankSelectContainer';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var WithdrawWorkflowsComponent = (function(_React$Component) {
  _inherits(WithdrawWorkflowsComponent, _React$Component);

  function WithdrawWorkflowsComponent() {
    _classCallCheck(this, WithdrawWorkflowsComponent);

    return _possibleConstructorReturn(
      this,
      (
        WithdrawWorkflowsComponent.__proto__ ||
        Object.getPrototypeOf(WithdrawWorkflowsComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(WithdrawWorkflowsComponent, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          templateType = _props.templateType,
          loading = _props.loading;

        var WorkflowComponent = null;

        switch (templateType) {
          case 'TrustPay': {
            WorkflowComponent = React.createElement(
              TrustpayWithdrawWorkflowContainer,
              null
            );
            break;
          }
          case 'Hyphen': {
            WorkflowComponent = React.createElement(
              HyphenWithdrawWorkflowContainer,
              null
            );
            break;
          }
          case 'EOTC': {
            WorkflowComponent = React.createElement(
              EOTCWithdrawWorkflowContainer,
              null
            );
            break;
          }
          default: {
            break;
          }
        }
        return !loading ? WorkflowComponent : null;
      }
    }
  ]);

  return WithdrawWorkflowsComponent;
})(React.Component);

WithdrawWorkflowsComponent.propTypes = {
  templateType: PropTypes.string,
  loading: PropTypes.bool.isRequired
};

export default WithdrawWorkflowsComponent;
