import { connect } from 'react-redux';
import TrustpayWithdrawWorkflowComponent from './TrustpayWithdrawWorkflowComponent';

var mapStateToProps = function mapStateToProps(state) {
  return {
    url:
      state.withdraw.withdrawWorkflowInfo &&
      state.withdraw.withdrawWorkflowInfo.URL
  };
};

export default connect(mapStateToProps)(TrustpayWithdrawWorkflowComponent);
