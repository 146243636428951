import React from 'react';
import PropTypes from 'prop-types';
import { templateFormRenderer } from 'apex-web/lib/helpers/formTemplateHelper';

import APButton from 'apex-web/lib/components/common/APButton';

const displayHCSdeposit = product => {
  if (product.Product === 'HCS') {
    return React.createElement(
      'div',
      { className: 'HCSmessage' },
      React.createElement(
        'h1',
        { className: 'HCSmessageHeader' },
        'HCS Deposit Instructions:'
      ),
      React.createElement(
        'h3',
        { className: 'HCSmessageBold' },
        '1. Enter the amount of HCS you are planning to deposit'
      ),
      React.createElement(
        'h3',
        { className: 'HCSmessageBold' },
        '2. Enter the wallet address that you are sending HCS from'
      ),
      React.createElement(
        'h3',
        { className: 'HCSmessageBold' },
        '3. Send HCS Tokens to the following wallet address:'
      ),
      React.createElement('img', {
        src:
          'https://elasticbeanstalk-us-east-1-777190501529.s3.amazonaws.com/hcsDepositKey.png',
        className: 'HCSimg'
      }),
      React.createElement(
        'h3',
        { className: 'HCSmessageAddress' },
        '0xBC56B3662C25D9836E45656c1008771B2b919584'
      ),
      React.createElement(
        'h3',
        { className: 'HCSmessageBold' },
        '4. Once you have sent the HCS tokens to the address above, exchange staff will reach out to you via email for signage of Transfer Agreement'
      )
    );
  }
};

var DepositTemplateFormComponent = function DepositTemplateFormComponent(
  _ref,
  context
) {
  var template = _ref.template,
    submitting = _ref.submitting,
    baseClasses = _ref.baseClasses,
    product = _ref.product;

  if (Object.keys(template).length) {
    var viewOnlyForm = Object.values(template).every(function(val) {
      return !!val;
    });

    return React.createElement(
      'div',
      { className: baseClasses('fields') },
      viewOnlyForm &&
        React.createElement(
          'p',
          null,
          context.t('Please use the following information to make a deposit:')
        ),
      templateFormRenderer(template, baseClasses(), context, product),
      !viewOnlyForm &&
        React.createElement(
          'div',
          { className: 'form-group' },
          displayHCSdeposit(product),
          React.createElement(
            APButton,
            {
              type: 'submit',
              disabled: submitting,
              customClass: baseClasses(),
              styleName: 'additive'
            },
            submitting
              ? context.t('Submitting...')
              : context.t('Place Deposit Ticket')
          )
        )
    );
  }
  return React.createElement('div', null);
};

DepositTemplateFormComponent.defaultProps = {
  product: PropTypes.object,
  template: {},
  submitting: false,
  baseClasses: function baseClasses() {}
};

DepositTemplateFormComponent.propTypes = {
  template: PropTypes.object,
  submitting: PropTypes.bool,
  baseClasses: PropTypes.func
};

DepositTemplateFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositTemplateFormComponent;
