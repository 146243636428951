import { connect } from 'react-redux';
import { openRetailSendReceiveSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import CryptoNavItemsComponent from './CryptoNavItemsComponent';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    openSendReceiveSidePane: function openSendReceiveSidePane(isSend, product) {
      dispatch(openRetailSendReceiveSidePane(isSend, product));
    }
  };
};

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var productPosition = Object.values(positionSelector(state)).find(function(
    p
  ) {
    return p.ProductId === ownProps.product.ProductId;
  });

  var Amount = 0;
  if (productPosition) Amount = productPosition.Amount;

  return {
    isWithdrawDisabled: Amount < 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CryptoNavItemsComponent);
