import React from 'react';
import { Link } from 'react-router-dom';
import './WelcomeCard.css';

export const WelcomeCard = () => {
  return (
    <React.Fragment>
      <div className="welcomeCard">
        <div className="cardItems">
          <h1 className="cardElements">Welcome to CryptoSX STO exchange</h1>
          <h3 className="cardElements">
            Just a few more steps to get your Investor Account verified
          </h3>
          <ul className="cardElements welcomeList">
            <li className="welcomeListItem">
              Confirm your Identity through the Verification process
            </li>
            <li className="welcomeListItem">
              Become an Accredited Investor through an easy-to-follow financial
              verification process
            </li>
          </ul>
          <div className="ap-button__btn ap-button__btn--additive welcomeBtn gardient_button">
            <Link style={{ color: 'white' }} to={`/settings/verification`}>
              START NOW
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WelcomeCard;
